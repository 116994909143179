import React, { useEffect, useContext, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { AppSettings } from '../../../config/app-settings.js';
import dishesServices from '../../../api/DishesServices/disheseService.js';
import CounterModal from './CounterModal.js';
import taxServices from '../../../api/TaxServices/taxServices.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiPaymentModal from './MultiPaymentModal.js';
import PosHeader from '../../../components/topbar/posHeader.jsx';
import CenteredModalFloat from "./CenteredModalFloat.js";
import Swal from 'sweetalert2';
import GiftCardModal from './GiftCardModal.js';
import ExicutiveModal from "./ExicutiveModal.js";
import executiveService from "../../../api/ExecutiveServices/executiveService.js";
import ReprintModal from './ReprintModal.js';
import ReprintManagerAccessModal from './ReprintManagerAcsessModal.js';

function PosCounterCheckout() {

	const context = useContext(AppSettings);
	const [time] = useState(getTime());
	const [filteredTableData, setFilteredTableData] = useState([]);
	const [posMobileSidebarToggled, setPosMobileSidebarToggled] = useState(false);
	const [selectedTable, setSelectedTable] = useState([]);
	const [modalShow, setModalShow] = React.useState(false);
	const [showModal, setShowModal] = React.useState(false);
	const [modalShow_2, setModalShow_2] = React.useState(false); 
	const [modalShow_3, setModalShow_3] = React.useState(false); 
	const [modalShow_4, setModalShow_4] = React.useState(false);
	const [modalShow_5, setModalShow_5] = React.useState(false); 
	const [searchTerm, setSearchTerm] = useState('');
	const [rfid, setRfid] = useState('');
	const [Taxes, setTaxes] = useState([]);
	const [paymentMethod, setPaymentMethod] = useState('');
	const [isInvalid, setIsInvalid] = useState(false);
	const [cardValidation, setCardValidation] = useState([]);
	const [cardCount, setCardCount] = useState([]);
	const [executiveList, setExecutiveList] = useState([]);
	const [stallId, setStallId] = useState([]);
	const [selectedExecutiveType, setSelectedExecutiveType] = useState("");
	const [note, setNote] = useState("");
	const WebSocketUrl = process.env.REACT_WEBSOCKET_URL;
	const [showManagerAccessModal, setShowManagerAccessModal] = useState(false);

	let TotalTaxesPrice = 0;
	let cardcount = 0;
	var taxTotals = [];
	var cardList= [];
	var orders = [];

	useEffect(() => {
		fetchOrdersUsingRfid();
	  }, [rfid]);
	  
	  useEffect(() => {
		cardcount= cardCount.length
	  }, [cardCount]);	

  
  useEffect(() => {
	addMultipleToSelectedTable(filteredTableData);

  }, [filteredTableData]);

  useEffect(() => {
	

if (selectedTable) {
  selectedTable.forEach((transa) => {
    if (transa && transa.transactionItems) {
      transa.transactionItems.forEach((order) => {
        orders.push(order);
      });
    }
  });
}
localStorage.setItem('orderData', JSON.stringify(orders));
	
  }, [selectedTable]);
  
  const addMultipleToSelectedTable = (newItems) => {
	setSelectedTable((prevTable) => {
	  const uniqueNewItems = newItems.filter((newItem) => {
		return !prevTable.some((existingItem) => existingItem.transactionID === newItem.transactionID);
	  });

	  return [...prevTable, ...uniqueNewItems];
	});
  };

	function checkTime(i) {
		if (i < 10) {i = "0" + i};
		return i;
	}
	
	function getTime() {
		var today = new Date();
		var h = today.getHours();
		var m = today.getMinutes();
		var a;
		m = checkTime(m);
		a = (h > 11) ? 'pm' : 'am';
		h = (h > 12) ? h - 12 : h;
	
		setTimeout(() => {
			getTime();
		}, 500);
		return h + ":" + m + a;
	}
	
	function toggleMobileSidebar(event, table) {
		event.preventDefault();
		
		setPosMobileSidebarToggled(true);
	}
	
	function dismissMobileSidebar(event) {
		event.preventDefault();
		
		setPosMobileSidebarToggled(false);
		setSelectedTable([]);
	}
	
	var getSubTotalPrice = () => {
		var  subtotal = 0;
		if(selectedTable){
		selectedTable.forEach( (sub) => {
			subtotal += sub.subTotal

		});
		return subtotal.toFixed(2) || 0;
	}}
	
	var getTotalPrice = () =>  {
		var  total = 0;
		if(selectedTable){
		selectedTable.forEach( (tot) => {
			total += tot.totalAmount

		});
		return total.toFixed(2) || 0;
	}}
	
	var getTaxesPrice = (taxCode) => {
		var TaxesPrice = 0;
		var taxPrese = 0;
		var taxItemId ='';
	
		if (selectedTable) {
			selectedTable.forEach((sele) => {
				if (sele.transactionItems) {
					sele.transactionItems.forEach((order) => {
						if (order.taxItems) {
							order.taxItems.forEach((tax) => {
								if (tax.taxCode === taxCode) {
									TaxesPrice += (tax.taxValue * order.quantity);
									taxPrese = tax.taxPercentage;
									taxItemId = tax.taxItemId;

								}
							});
						}
					});
				}
			});
			
			TotalTaxesPrice += TaxesPrice;
	
			let existingTax = taxTotals.find(t => t.taxCode === taxCode); 
			if (existingTax) {
				existingTax.taxTotal = TaxesPrice.toFixed(2);
			} else {
				if (taxCode && TaxesPrice) {
					taxTotals.push({
						taxCode: taxCode,
						taxPrese: taxPrese,
						taxTotal: TaxesPrice.toFixed(2),
						taxListId: taxItemId 
					});
				}
			}
			
			return TaxesPrice.toFixed(2);
		}
		
		return '0.00';
	};
	
	
	function getTTransactionIds() {
		var TransactionIdList= []
		

		if(selectedTable){
		selectedTable.forEach( (sub) => {
			TransactionIdList.push(sub.transactionID);
			if (!cardList.includes(sub.cardId)) {
				cardList.push(sub.cardId);
			  }
		});
		
	}
	return TransactionIdList;
}
	const fetchTaxes = async () => {
		try {
		  const response = await taxServices().getAllTax();
		  setTaxes(response.data); 
		  
		} catch (error) {
		  console.error('Error fetching suppliers data:', error);
		}
	  };

	const fetchExecutiveData = async () => {
		try {
		  const response = await executiveService().getAllExecutiveList();
		   
		  setExecutiveList(response); 
		  
		} catch (error) {
		  console.error('Error fetching suppliers data:', error);
		}
	  };

	  const removeTable = (cardId) => {
		const updatedTableData = selectedTable.filter(table => table.cardId !== cardId);
		setSelectedTable(updatedTableData);
		const updatedCardCount = cardCount.filter(card => card !== cardId);
		setCardCount(updatedCardCount);
	  };

	  const cards = async () => {
		try {
			const response = await dishesServices().getAllCards();
			setCardValidation(response.data);
		} catch (error) {
			console.error("Error fetching cards:", error);
		}
		};

		const fetchOrders = async () => {
					try {
				if (!searchTerm) {
					setIsInvalid(false);
					return;
				}
		
				const foundCard = cardValidation?.find(
					card => card.card_Id === searchTerm || card.kcc_Id === searchTerm
				) || null;
		
				if (foundCard) {
					setSearchTerm(foundCard.card_Id)
					const response = await dishesServices().getAllCardOreders(foundCard.card_Id);
					setFilteredTableData(response);
					setIsInvalid(response.length === 0);
					if (foundCard.card_Id && !cardCount.includes(foundCard.card_Id)) {
						setCardCount(prevCardCount => [...prevCardCount, foundCard.card_Id]);
					}
				} else {
					console.error("Card not found.");
					setIsInvalid(true);
				}
			} catch (error) {
				console.error("An error occurred:", error);
				setIsInvalid(true);
			}
		};
		const fetchOrdersUsingRfid = async () => {
			try {
				if (!rfid) {
					setIsInvalid(false);
					return;
				}
				
				const response = await dishesServices().getAllCardOreders(rfid);
				setFilteredTableData(response);
				setIsInvalid(response.length === 0);
				if (searchTerm && !cardCount.includes(searchTerm)) {
					setCardCount(prevCardCount => [...prevCardCount, searchTerm]);
				}
			} catch (error) {
				console.error("An error occurred:", error);
				setIsInvalid(true);
			}
		};
		

		const finish = async () => {
			try {
					setSelectedTable([]);
					setSearchTerm('');
					cardList = [];
					orders = [];
					setCardCount([]);
			} catch (error) {
				console.error("ERROR");
			}
		};

		const cardClear = async () => {
			try {
				const result = await Swal.fire({
					title: 'Are you sure?',
					text: "Do you really want to clear all data?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, clear it!',
					cancelButtonText: 'No, keep it',
					confirmButtonColor: '#d33',
					cancelButtonColor: '#3085d6',
				});
				
				if (result.isConfirmed) {
					setSelectedTable([]);
					setSearchTerm('');
					setRfid('')
					cardList = [];
					orders = [];
					setCardCount([]);
				}
			} catch (error) {
				console.error("ERROR");
			}
		};
		
	  

// Check if selectedTable has a value

	  useEffect(() => {
		const ws = new WebSocket('ws://20.92.202.139:3600');
	
		ws.onopen = () => {
		  console.log('Connected to WebSocket server');
		};
	
		ws.onmessage = (event) => {
		  const data = JSON.parse(event.data);
		 if((localStorage.getItem('stallID')).includes(data.unique_key)){
			setRfid(data?.decimal_value)
			setSearchTerm(data?.decimal_value)
		  };
		};
	
		ws.onclose = () => {
		  console.log('WebSocket connection closed');
		};
	
		return () => {
		  ws.close();
		};
	  }, []);

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentFullHeight(true);
		context.setAppContentClass('p-0');
		setStallId(localStorage.getItem('stallID'));
		
		fetchTaxes();
		fetchExecutiveData();
		cards();
		setCardCount([])

		const floatValue = localStorage.getItem("floatValue") ? true : false;
		if (floatValue) { 
			setShowModal(true);
		}
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentFullHeight(false);
			context.setAppContentClass('');
		};
	}, 
	[]);
	
	return (
		<>
		<CenteredModalFloat
			show={showModal}
			onHide={() => setShowModal(false)}
			onSubmit={(amount) => {
				setShowModal(false);  
			}}
		/> 
		<ToastContainer />
		<div className={'pos pos-vertical pos-with-header pos-with-sidebar ' + ((posMobileSidebarToggled) ? 'pos-mobile-sidebar-toggled' : '')} id="pos">
			<div className="pos-container">
			<div className="pos-header fixed-header">
				<PosHeader />
			</div>
				
				<div className="pos-content">
					<div className="pos">
						<div className="pos-container">
							<div className="pos-content h-100">
								<div className="pos-content-container p-3 h-100">
								<div className="row gx-3">
								{selectedTable && selectedTable.length > 0 ? (
									[...new Map(selectedTable.map(item => [item.cardId, item])).values()]
									.map((table, index) => (
										<div className="col-12 pb-2 d-flex justify-content" key={`${table.cardId}-${index}`}>
										<div
											className={'pos-checkout-table' +
											((selectedTable && table.transactionID === selectedTable.transactionID) ? ' selected' : '') +
											((!table.orders && table.status !== 'Reserved') ? ' available' : '') +
											((table.orders) ? ' in-use' : '') +
											((table.status === 'Reserved') ? ' disabled' : '')
											}
										>
										<a href="#/" className="pos-checkout-table-container" onClick={(event) => toggleMobileSidebar(event, table)}>
											<div className="pos-checkout-table-header">
											<button 
											className="btn btn-danger btn-sm" 
											onClick={() => removeTable(table.cardId)}
											style={{top: '10px', right: '10px'}}
										>
											X
										</button>
											
											<div className="fw-bold fs-1">{table.rfidCardId}</div>
											<div className="fs-13px text-body text-opacity-50"
											style={{ fontSize: '18px', fontWeight: 'bold', color: 'black'}}>
												{table.transactionItems && (<span>{table.transactionItems.length} orders</span>)}
											</div>
											</div>
										</a>
										</div>
									</div>
									))
								) : (
									<div className="col-12">
									No records found
									</div>
								)}
								</div>
								</div>
							</div>
			
							<div className="pos-sidebar" id="pos-sidebar" style={{ width: '800px'}}>
								<div className="pos-sidebar-header" >
									<div className="back-btn">
										<button type="button" onClick={dismissMobileSidebar} className="btn">
											<i className="fa fa-chevron-left"></i>
										</button>
									</div>
									<div className="col-12 mb-3 d-flex align-items-center">
									<input
										type="text"
										className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
										placeholder="Enter Card No."
										value={searchTerm}
										style={{
										borderColor: isInvalid ? 'red' : 'initial',
										boxShadow: isInvalid ? '0 0 10px red' : 'none',
										transition: 'box-shadow 0.3s ease, border-color 0.3s ease',
										backgroundColor: isInvalid ? 'rgba(255, 0, 0, 0.1)' : 'transparent'
										}}
										onChange={(e) => {
										const value = e.target.value;
										
											setSearchTerm(value);
										}}
									/>

									<button
										className="btn btn-primary ms-3"
										onClick={fetchOrders}
									>
										ADD
									</button>
									<button
										className="btn btn-primary ms-3"
										onClick={cardClear}
									>
										CLEAR
									</button>
									</div>
								</div>
								<hr className="m-0 opacity-1" />
								<PerfectScrollbar className="pos-sidebar-body">
								<div>
									{selectedTable ? (
										selectedTable.map((transa, transaIndex) =>
										transa && transa.transactionItems ? (
											transa.transactionItems.map((order, orderIndex) => {
											let totalAddOnsPrice = 0;
											
											return (
												<div className="pos-order py-3" key={orderIndex}>
												<div className="pos-order-product">
													<div className="flex-1">
													<div className="row">
														<div className="col-7">
														<div className="fs-6 fw-semibold">{transa.stallId.substring(2)} - {order.itemName}</div>

														{order.addOns && order.addOns.map((addOn, addOnIndex) => {
															const addOnPrice = addOn.price || 0;
															totalAddOnsPrice += addOnPrice;

															return (
															<div key={addOnIndex}>
																- {addOn.key}: {addOn.addOn} - RS {addOnPrice.toFixed(2)}
															</div>
															);
														})}
														</div>
														<div className="col-2">x{order.quantity}</div>
														<div className="col-3 text-body fw-semibold text-end">
														RS {((parseFloat(order.itemPrice) + totalAddOnsPrice) * order.quantity).toFixed(2)}
														</div>
													</div>
													</div>
												</div>
												</div>
											);
											})
										) : (
											<div className="pos-order py-3" key={transaIndex}>
											No records found
											</div>
										)
										)
									) : (
										<div className="pos-order py-3">
										No records found
										</div>
									)}
									</div>

								</PerfectScrollbar>
								
								<div className="pos-sidebar-footer">
								<div className="d-flex align-items-center mb-2">
									<div>Number of Cards</div>
									<div className="flex-1 text-end h4 mb-0">{cardCount.length}</div>
									</div>
								<hr className="opacity-1 my-10px" />
								<div className="d-flex align-items-center mb-2">
									<div>Subtotal</div>
									<div className="flex-1 text-end h6 mb-0">RS {getSubTotalPrice()}</div>
								</div>
								<div className="small mb-2">
									{Taxes && Taxes.map((tax, index) => (
									<div key={index} className="d-flex justify-content-between">
										<div>{tax.taxCode}: {tax.taxRate} %</div>
										<div className="flex-1 text-end h6 mb-0">RS {getTaxesPrice(tax.taxCode)}</div>
									</div>
									))}
								</div>
								<hr className="opacity-1 my-10px" />
								<div className="d-flex align-items-center mb-2">
									<div>Total</div>
									<div className="flex-1 text-end h4 mb-0">RS {getTotalPrice()}</div>
								</div>
								<div className="mt-3">
									<div className="d-flex justify-content-between">
									<a
									href="#/"
									className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 ${selectedTable.length === 0 ? 'disabled' : ''}`}
									style={{ maxWidth: '150px' }}
									onClick={(e) => {
										e.preventDefault();
										if (selectedTable.length > 0) {
										setModalShow_2(true);
										setPaymentMethod('CASH');
										}
									}}
									disabled={selectedTable.length === 0}
									>
									<span>
									<span className="small fw-bold" style={{ fontSize: '15px', textAlign: 'center' }}>
										MULTI PAY
									</span>
									</span>
									</a>

									<MultiPaymentModal
									show={modalShow_2}
									onHide={() => setModalShow_2(false)}
									grossTotal={parseFloat(getSubTotalPrice())}
									Tax={taxTotals}
									Total={parseFloat(getTotalPrice())}
									paymentMethod={"MULTY"}
									transactionIds={getTTransactionIds()}
									finish={finish}
									cardList={cardList}
									selectedExecutiveType={selectedExecutiveType}
									setSelectedExecutiveType={setSelectedExecutiveType}
									note={note}
									setNote={setNote}
									/>
									{/* <a
									href="#/"
									className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 ${selectedTable.length === 0 ? 'disabled' : ''}`}
									style={{ maxWidth: '150px' }}
									onClick={(e) => {
										e.preventDefault();
										if (selectedTable.length > 0) {
										setModalShow_3(true);
										setPaymentMethod('CARD');
										}
									}}
									disabled={selectedTable.length === 0}
									><span>
									<span className="small fw-bold" style={{ fontSize: '15px', textAlign: 'center' }}>
										GIFT CARD PAY
									</span>
									</span>
									</a> */}
                                
									<GiftCardModal
									show={modalShow_3}
									onHide={() => setModalShow_3(false)}
									subTotal={getSubTotalPrice()}
									Tax={taxTotals}
									Total={getTotalPrice()}
									giftCardNumber={searchTerm}
									transactionIds={getTTransactionIds()}
									finish={finish}
									cardList={cardList}
									selectedExecutiveType={selectedExecutiveType}
									setSelectedExecutiveType={setSelectedExecutiveType}
									note={note}
									setNote={setNote}
									/>

									<a
									href="#/"
									className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 ${selectedTable.length === 0 ? 'disabled' : ''}`}
									style={{ maxWidth: '150px' }}
									onClick={(e) => {
										e.preventDefault();
										if (selectedTable.length > 0) {
										setModalShow_4(true);
										setPaymentMethod("EXECUTIVE");
										}
									}}
									disabled={selectedTable.length === 0}
									><span>
									<span className="small fw-bold" style={{ fontSize: '15px', textAlign: 'center' }}>
									COMPLIMENTARY BILL
									</span>
									</span>
									</a>

									{/* New REPRINT Button */}
									<a
									href="#/"
									className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 `}
									style={{ 
										backgroundColor: '#0A3981',
										maxWidth: '150px',
										marginLeft: '70px' 
									}}
									onClick={(e) => {
										e.preventDefault();
										setModalShow_5(true);
										//setShowManagerAccessModal(true);
									}}
									>
									<span>
										<span className="small fw-bold" style={{ fontSize: '15px', textAlign: 'center' }}>
										RE PRINT
										</span>
									</span>
									</a>

									<ReprintManagerAccessModal
									show={showManagerAccessModal}
									onHide={() => setShowManagerAccessModal(false)}
									onSuccess={() => {
										setModalShow_5(true);
									}}
									/>

									{/* REPRINT Modal */}
									<ReprintModal
									show={modalShow_5}
									onHide={() => setModalShow_5(false)}
									transactionIds={getTTransactionIds()}
									cardList={cardList}
									/>

									<ExicutiveModal
										show={modalShow_4}
										onHide={() => setModalShow_4(false)}
										grossTotal={parseFloat(getSubTotalPrice())}
										Tax={taxTotals}
										Total={parseFloat(getTotalPrice())}
										giftCardNumber={searchTerm}
										transactionIds={getTTransactionIds()}
										finish={finish}
										cardList={cardList}
										executiveList={executiveList}
										paymentMethod={paymentMethod}
										selectedExecutiveType={selectedExecutiveType}
										setSelectedExecutiveType={setSelectedExecutiveType}
										note={note}
										setNote={setNote}
									/>    
									{/* <a
									href="#/"
									className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 ${selectedTable.length === 0 ? 'disabled' : ''}`}
									style={{ maxWidth: '150px' }}
									onClick={(e) => {
										e.preventDefault();
										if (selectedTable.length > 0) {
										setModalShow(true);
										setPaymentMethod('CARD');
										}
									}}
									disabled={selectedTable.length === 0}
									>
									<span>
									<span className="small fw-bold" style={{ fontSize: '15px' }}>CARD</span>
									</span>
									</a> */}
									{/* <a
									href="#/"
									className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center ${selectedTable.length === 0 ? 'disabled' : ''}`}
									style={{ maxWidth: '150px' }}
									onClick={(e) => {
										e.preventDefault();
										if (selectedTable.length > 0) {
										setModalShow(true);
										setPaymentMethod('CASH');
										}
									}}
									disabled={selectedTable.length === 0}
									>
									<span>
									<span className="small fw-bold" style={{ fontSize: '15px' }}>CASH</span>
									</span>
									</a> */}
									<CounterModal
									show={modalShow}
									onHide={() => setModalShow(false)}
									subTotal={getSubTotalPrice()}
									Tax={taxTotals}
									Total={getTotalPrice()}
									paymentMethod={paymentMethod}
									transactionIds={getTTransactionIds()}
									finish={finish}
									cardList={cardList}
									selectedExecutiveType={selectedExecutiveType}
									setSelectedExecutiveType={setSelectedExecutiveType}
									note={note}
									setNote={setNote}
									/>
									</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	)
}

export default PosCounterCheckout;