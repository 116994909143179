const BASE_URL = process.env.REACT_APP_BASE_URL;
//const BASE_URL = "https://localhost:7227/api";
const GATE_CONTROL_ACCESS_URL = process.env.REACT_APP_GATE_CONTROL_ACCESS_URL;

export const API_CONFIGURATIONS = {
    GET_ALL_DISHERS: `${BASE_URL}/MenuItems`,
    GET_MENU_ITEMS_BY_STALL: `${BASE_URL}/MenuItems/stall`,

    POST_ORDERS: `${BASE_URL}/Transaction`,
    GET_ORDERS: `${BASE_URL}/Transaction`,
    GET_FILTERED_ORDERS: `${BASE_URL}/Transaction/filtered-transactions`,
    GET_IN_PROGRESS_ORDERS: `${BASE_URL}/Transaction/in progress`,
    GET_ALL_CARD_ORDERS: `${BASE_URL}/Transaction/card`,
    GET_ALL_TABLE_ORDERS: `${BASE_URL}/Transaction/table`,
    POST_VOID_ORDERS: `${BASE_URL}/Transaction/void-linked`,
    PUT_ORDERS: `${BASE_URL}/Transaction`,
    PUT_OUTLET_VOID_ORDERS: `${BASE_URL}/Transaction/batch`,
    POST_COLLECTIVE_ORDERS: `${BASE_URL}/Transaction/create-collect-transaction`,
    POST_OUTLET_COLLECTIVE_ORDERS: `${BASE_URL}/Transaction/create-outlet-collect-transaction`,
    UPDATE_OUTLET_COLLECTIVE_ORDERS: `${BASE_URL}/Transaction/update-outlet-collect-transaction`,
    GET_VOID_REASONS: `${BASE_URL}/Transaction/void-reasons`,
    POST_VALIDATE_MANAGER: `${BASE_URL}/Transaction/validate-manager`,
    POST_VALIDATE_ACCOUNTANT: `${BASE_URL}/Transaction/validate-accountant`,
    POST_VALIDATE_CHIEF_CASHIER: `${BASE_URL}/Transaction/validate-chief-cashier`,  
    UPDATE_ORDER_STATUS: `${BASE_URL}/Transaction/unsettle`,


    GET_ALL_TAX: `${BASE_URL}/TaxList`,
    GET_ALL_DISCOUNTS: `${BASE_URL}/Discount`,
    GET_LOGIN: `${BASE_URL}/Auth/login`,

    GET_TABLES: `${BASE_URL}/Table`,
    GET_CARD: `${BASE_URL}/Card`,
    POST_CARD_STATUS: `${BASE_URL}/Card/update-card-status`,
    POST_CARD_STATUS_VOID: `${BASE_URL}/Card/void-card-status`,
    GET_MANAGER_CARD: `${BASE_URL}/Card/manager-cards`,

    POST_CARD_GATE_CONTROL: `${GATE_CONTROL_ACCESS_URL}/update`,

    GET_ALL_DISCOUNTS: `${BASE_URL}/Discount`,
    GET_CASHIER: `${BASE_URL}/CollectTransactionHeader`,
    GET_CASHIER_TODAY: `${BASE_URL}/CollectTransactionHeader/today`,
    GET_STALLS: `${BASE_URL}/Stall`,

    POST_LOG_DETAIL: `${BASE_URL}/LogDetail`,
    GET_LOG_DETAIL: `${BASE_URL}/LogDetail`,
    PUT_LOG_DETAIL: `${BASE_URL}/LogDetail`,
    HANDLE_FLOAT_VALUE: `${BASE_URL}/LogDetail/float-value`,
    HANDLE_EXIT_STATUS: `${BASE_URL}/LogDetail/exit-status`,
    UPDATE_LOGOUT_TIME: `${BASE_URL}/LogDetail/logout`,
    POST_CUSTOMER_CONTACTS: `${BASE_URL}/Transaction/add-customer-contact`,

    GET_ALL_EXECUTIVE_LIST: `${BASE_URL}/ExecutiveList`,

    //Acumatica Syncing
    SYNC_ACUMATICA_USER_ROLE_INTERGRATION: `${BASE_URL}/AcumaticaUserRoleIntegration/sync`,
    SYNC_ACUMATICA_STALL_MENUITEM_INTERGRATION: `${BASE_URL}/AcumaticaStallMenuItemIntegration/sync`,
    SYNC_ACUMATICA_DISCOUNT_INTERGRATION: `${BASE_URL}/AcumaticaDiscountIntegration/sync`,
    SYNC_ACUMATICA_TAX_LIST: `${BASE_URL}/AcumaticaTaxList/sync`,
    SYNC_ACUMATICA_USER_STALLS_INTERGRATION: `${BASE_URL}/AcumaticaUserStallsIntegration/sync`,
    SYNC_ACUMATICA_VOID_REASONS_INTEGRATION: `${BASE_URL}/AcumaticaVoidReasonsIntegration/sync`,
    SYNC_ACUMATICA_EXECUTIVE_LIST_INTEGRATION: `${BASE_URL}/AcumaticaExecutiveListIntegration/sync`,

    //Push Transactions to Acumatica
    PUSH_TRANSACTIONS_ACUMATICA: `${BASE_URL}/AcumaticaPushData/pushAcumatica`
};