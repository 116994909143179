import React from "react";
import axios from "axios";
import {API_CONFIGURATIONS} from "../constants/apiConfigurations";
import { Balance } from "@mui/icons-material";
import { error } from "jquery";
import { displayName } from "react-quill";
const apiUrl = process.env.REACT_CONNECT_PRINTER_URL;

const printService = () => {
    

  const TaxType = Object.freeze({
    VAT: "VAT",
    BILLTYPE_KOT: "KOT",
    BILLTYPE_FULL_BILL: "BILL",
    TERMINAL_ID:localStorage.getItem('stallID'),
  });

const sendKOTBill = async (data) => {
  let totalVAT = 0;
  let response; 
  try {
    if (data.data.transactionItems) {
      data.data.transactionItems.flatMap((item) =>
        item.taxItems.map((vat) => {
          if (vat.taxType === TaxType.VAT) {
            totalVAT += (vat.taxValue * item.quantity)
          }
        })
      );
    }
    const displayName = localStorage.getItem("displayName") || "Unknown";

    const payload = {
      command: TaxType.BILLTYPE_KOT,
      stallId: data.data.stallId,
      kot_bot_no: 1,
      transactionID: data.data.transactionID.toString().padStart(10, '0'),
      //date: data.data.createdDateTime.split("T")[0],                    //Temporarily hidden
      date: new Date(data.data.createdDateTime).toLocaleString({ 
        timeZone: 'Asia/Colombo',
        year: 'numeric',
        month: '2-digit', 
        day: '2-digit'
      }).split(',')[0],
      //time: data.data.createdDateTime.split("T")[1].split(".")[0],     //Temporarily hidden
      time: new Date(data.data.createdDateTime).toLocaleString('en-US', {
        timeZone: 'Asia/Colombo',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }),
      cardId: data.data.rfidCardId,
      //employeeID: data.data.employeeID,
      employeeID: displayName.split(" ")[0],
      transactionItems: data.data.transactionItems.map((item) => ({
      itemName: item.itemName,
      itemPrice: (item.itemPrice).toFixed(2),
      quantity: item.quantity,
      amount: item.itemPrice * item.quantity,
      })),
      subTotal: data.data.subTotal,
      vat: totalVAT,
      totalAmount: data.data.totalAmount,
    };
    
    console.log(payload);
     response = await axios.post(`http://124.43.16.238:5015/api/print`, payload, {
       headers: { "Content-Type": "application/json" },
     });

    return response.data;
  } catch (error) {
    console.error("Error sending KOT bill:", error.message);
    throw new Error(`Failed to send KOT bill: ${error.message}`);
  }
};

const sendFullBill = async (data) => {
  let response;
  try {
    let payload;
    let balanceAmt;
    const displayName = localStorage.getItem("displayName") || "Unknown";
    
    if(data.data.collectiveTransactionId.stallId ==='BAKESNCAKE'||data.data.collectiveTransactionId.stallId ==='CAFEWALK'){
      if((data.data.collectiveTransactionId.cashAmount - data.data.collectiveTransactionId.totalBill)< 0){
        balanceAmt=0;
      }
      else{
        balanceAmt=Math.round(data.data.collectiveTransactionId.cashAmount - data.data.collectiveTransactionId.totalBill);
      }
      payload = {
      command: TaxType.BILLTYPE_FULL_BILL,
      stallId: data.data.collectiveTransactionId.stallId,
      invoice_no: data.data.collectiveTransactionId.collectiveTransactionID.toString().padStart(10, '0'),
      terminal: data.data.collectiveTransactionId.stallId,
      //date: data.data.collectiveTransactionId.tStamp.split("T")[0],                     //Temporarily hidden
      //time: data.data.collectiveTransactionId.tStamp.split("T")[1].split(".")[0],       //Temporarily hidden
      date: new Date(data.data.collectiveTransactionId.tStamp).toLocaleString({
          timeZone: 'Asia/Colombo',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
      }).split(',')[0],
      time: new Date(data.data.collectiveTransactionId.tStamp).toLocaleString('en-US', {
          timeZone: 'Asia/Colombo',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
      }),
      //employeeID: data.data.collectiveTransactionId.employeeID,
      employeeID: displayName.split(" ")[0],
      table_no: "-",
      transactionItems: data.data.collectiveTransactionId.transactions.flatMap(transaction =>
        transaction.transactionItems.map(item => ({
          itemName: item.itemName,
          itemPrice:Math.round((item.itemPrice+item.taxItems[0].taxValue)).toFixed(2),
          quantity: item.quantity,
          amount: Math.round((item.itemPrice+item.taxItems[0].taxValue) * item.quantity),
        }))
      ),
      gross_total: data.data.collectiveTransactionId.grossTotal,
      discount: data.data.collectiveTransactionId.discount,
      subTotal: data.data.collectiveTransactionId.subTotal,
      vat: data.data.collectiveTransactionId.vatTotal,
      totalAmount: data.data.collectiveTransactionId.totalBill,
      cashAmount: data.data.collectiveTransactionId.cashAmount,
      cardAmount: data.data.collectiveTransactionId.cardAmount,
      balance: balanceAmt
      
    };
     
  }
  else {
    if((data.data.collectiveTransactionId.cashAmount - data.data.collectiveTransactionId.totalBill)< 0){
      balanceAmt=0;
    }
    else{
      balanceAmt=data.data.collectiveTransactionId.cashAmount - data.data.collectiveTransactionId.totalBill;
    }
    payload = {
      command: TaxType.BILLTYPE_FULL_BILL,
      stallId: data.data.collectiveTransactionId.stallId,
      invoice_no: data.data.collectiveTransactionId.collectiveTransactionID.toString().padStart(10, '0'),
      terminal: data.data.collectiveTransactionId.stallId,
      //date: data.data.collectiveTransactionId.tStamp.split("T")[0],                     //Temporarily hidden
      //time: data.data.collectiveTransactionId.tStamp.split("T")[1].split(".")[0],       //Temporarily hidden
      date: new Date(data.data.collectiveTransactionId.tStamp).toLocaleString({
          timeZone: 'Asia/Colombo',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
      }).split(',')[0],
      time: new Date(data.data.collectiveTransactionId.tStamp).toLocaleString('en-US', {
          timeZone: 'Asia/Colombo',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
      }),
      //employeeID: data.data.collectiveTransactionId.employeeID,
      employeeID: displayName.split(" ")[0],
      table_no: "-",
      transactionItems: data.data.collectiveTransactionId.transactions.flatMap(transaction =>
        transaction.transactionItems.map(item => ({
          itemName: item.itemName,
          itemPrice: item.itemPrice.toFixed(2),
          quantity: item.quantity,
          amount: item.itemPrice * item.quantity,
        }))
      ),
      gross_total: data.data.collectiveTransactionId.grossTotal,
      discount: data.data.collectiveTransactionId.discount,
      subTotal: data.data.collectiveTransactionId.subTotal,
      vat: data.data.collectiveTransactionId.vatTotal,
      totalAmount: data.data.collectiveTransactionId.totalBill,
      cashAmount: data.data.collectiveTransactionId.cashAmount,
      cardAmount: data.data.collectiveTransactionId.cardAmount,
      balance: balanceAmt
    };
  }

  console.log("Full Bill -", payload);
  
     response = await axios.post(`http://124.43.16.238:5015/api/print`, payload, {
            headers: { "Content-Type": "application/json" },
     });
// debugger
    return response?.data;
  } catch (error) {
    console.error("Error sending Full bill:", error.message);
    throw new Error(`Failed to send Full bill: ${error.message}`);
  }
};

  const handleSaveCard = async (Card_Status,Card_Ids) => {
    try {
       
      const response = await axios
      .post(API_CONFIGURATIONS.POST_CARD_STATUS,
        {
          card_Status: Card_Status,
          card_Ids: Card_Ids,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
       
      return response;
    } catch (error) {
        throw new Error(error.message);
    }
  };

  const handleGateControlCard = async (Card_Status,Card_Ids) => {
    try {
      const response = await axios
      .put(API_CONFIGURATIONS.POST_CARD_GATE_CONTROL,
        {
          Card_Status: Card_Status,
          Card_Id: Card_Ids,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      
      return response;
    } catch (error) {
        throw new Error(error.message);
    }
  };

const sendVoidKOTBill = async (voidData, orderData) => {
  let totalVAT = 0;
  let response;
  try {
    if (voidData.transactionItems) {
       voidData.transactionItems.flatMap((item) =>
        item.taxItems.map((vat) => {
          if (vat.taxType === TaxType.VAT) {
            totalVAT += (vat.taxValue * item.quantity)
          }
        })
      );
    }
    const displayName = localStorage.getItem("displayName") || "Unknown";

    const payload = {
        command: "VOID",
        stallId: voidData.stallId,
        kot_bot_no: 1,
        transactionID: voidData.transactionID.toString().padStart(10, '0'),
        void_no: orderData[0].transactionID,
        //date: voidData.createdDateTime.split("T")[0],                     //Temporarily hidden
        date: new Date(voidData.createdDateTime).toLocaleString({
          timeZone: 'Asia/Colombo',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).split(',')[0],
        //time: voidData.createdDateTime.split("T")[1].split(".")[0],      //Temporarily hidden
        time: new Date(voidData.createdDateTime).toLocaleString('en-US', {
          timeZone: 'Asia/Colombo', 
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }),
        cardId: voidData.rfidCardId,
        //employeeID: voidData.employeeID,
        employeeID: displayName.split(" ")[0],
        //transactionItems: orderData,                                    //Temporarily hidden
        transactionItems: voidData.transactionItems.map((item) => ({
          itemName: item.itemName,
          itemPrice: (item.itemPrice).toFixed(2),
          quantity: item.quantity,
          amount: item.itemPrice * item.quantity,
        })),
        subTotal: voidData.subTotal,
        vat: totalVAT,
        totalAmount: voidData.totalAmount,
      };

       response = await axios.post(`http://124.43.16.238:5015/api/print`, payload, {
         headers: { "Content-Type": "application/json" },
       });

    return response.data;
  } catch (error) {
  console.error("Error sending VOID KOT bill:", error.message);
  throw new Error(`Failed to send VOID KOT bill: ${error.message}`);
  }
};

  const handleGateControlCardWithVoid = async (cardId) => {
    try {
       
      const response = await axios.post(`${API_CONFIGURATIONS.POST_CARD_STATUS_VOID}?cardId=${cardId}`,{},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  };

const sendFloatValue = async (handleFloatValue) => {
   let response;
   try {
   const displayName = localStorage.getItem("displayName") || "Unknown";

   const payload = {
    command: "SIGN_ON",
    stallId: handleFloatValue.stallId,
    //date: handleFloatValue.lastLoginTime.split("T")[0],
    date: new Date(handleFloatValue.lastLoginTime).toLocaleString({
      timeZone: 'Asia/Colombo',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).split(',')[0],
    //time: handleFloatValue.lastLoginTime.split("T")[1].split(".")[0],
    time: new Date(handleFloatValue.lastLoginTime).toLocaleString('en-US', {
      timeZone: 'Asia/Colombo', 
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }),
    //cashierId: handleFloatValue.cashierId,
    cashierId: displayName.split(" ")[0],
    cashierAmount: handleFloatValue.cashierAmount,
   };
  
   response = await axios.post(`http://124.43.16.238:5015/api/print`, payload, {
     headers: { "Content-Type": "application/json" },
   });

 return response.data;
} catch (error){
  console.error("Error sending Float bill:", error.message);
  throw new Error(`Failed to send Float bill: ${error.message}`);
}
};

const sendOutletKOTBill = async (data) => {
   
  let response; 

  const outletStallId = localStorage.getItem("stallID");
   
  try {
      
    const payload = {
      command: "KOT",
      stallId: data.stallId,
      kot_bot_from: outletStallId,
      transactionID: data.transactionID.toString().padStart(10, "0"), 
      date: new Date(data.createdDateTime).toLocaleString({timeZone: "Asia/Colombo",year: "numeric",month: "2-digit",day: "2-digit"}).split(",")[0],
      time: new Date(data.createdDateTime).toLocaleString("en-US", {timeZone: "Asia/Colombo",hour: "2-digit",minute: "2-digit",second: "2-digit",hour12: false}),
      table_no: data.tableIds, 
      transactionItems: data.transactionItems.map((item) => ({
        itemName: item.itemName,
        itemPrice: item.itemPrice,
        quantity: item.quantity,
        amount: item.itemPrice * item.quantity,
      })), 
    };

    //console.log("Outlet KOT/BOT - ", outletStallId, data.stallId, payload);
    
    //----- plz uncomment this API when successfully connecting KOT/BOT printers ready to catch the API request (to Sachika/Dulaj/Sandalu).
    response = axios.post(`http://124.43.16.238:5015/api/print`, payload, {headers: { "Content-Type": "application/json" }});

    return response?.data;

  } catch (error) {
    console.error("Error sending Outlet KOT bill:", error.message);
    throw new Error(`Failed to send Outlet KOT bill: ${error.message}`);
  }
};

const sendOutletBOTBill = async (data) => {
   
  let response; 

  const outletStallId = localStorage.getItem("stallID");
   
  try {
      
    const payload = {
      command: "BOT",
      stallId: data.stallId,
      kot_bot_from: outletStallId,
      transactionID: data.transactionID.toString().padStart(10, "0"), 
      date: new Date(data.createdDateTime).toLocaleString({timeZone: "Asia/Colombo",year: "numeric",month: "2-digit",day: "2-digit"}).split(",")[0],
      time: new Date(data.createdDateTime).toLocaleString("en-US", {timeZone: "Asia/Colombo",hour: "2-digit",minute: "2-digit",second: "2-digit",hour12: false}),
      table_no: data.tableIds, 
      transactionItems: data.transactionItems.map((item) => ({
        itemName: item.itemName,
        itemPrice: item.itemPrice,
        quantity: item.quantity,
        amount: item.itemPrice * item.quantity,
      })), 
    };

    //console.log("Outlet KOT/BOT - ", outletStallId, data.stallId, payload);
    
    //----- plz uncomment this API when successfully connecting KOT/BOT printers ready to catch the API request (to Sachika/Dulaj/Sandalu).
    response = axios.post(`http://124.43.16.238:5015/api/print`, payload, {headers: { "Content-Type": "application/json" }});

    return response?.data;

  } catch (error) {
    console.error("Error sending Outlet KOT bill:", error.message);
    throw new Error(`Failed to send Outlet KOT bill: ${error.message}`);
  }
};

const sendOutletVoidKOTBill = async (data,voidFrom) => {
   
  let response; 

  const outletStallId = localStorage.getItem("stallID");
   
  try {
     
    const payload = {
      command: "KOT",
      stallId: data.stallId,
      kot_bot_from: outletStallId,
      transactionID: data.transactionID.toString().padStart(10, "0"), 
      void_no: voidFrom, 
      date: new Date(data.createdDateTime).toLocaleString({timeZone: "Asia/Colombo",year: "numeric",month: "2-digit",day: "2-digit"}).split(",")[0],
      time: new Date(data.createdDateTime).toLocaleString("en-US", {timeZone: "Asia/Colombo",hour: "2-digit",minute: "2-digit",second: "2-digit",hour12: false}),
      table_no: data.tableIds, 
      transactionItems: data.transactionItems.map((item) => ({
        itemName: item.itemName,
        itemPrice: item.itemPrice,
        quantity: item.quantity,
        amount: item.itemPrice * item.quantity,
      })), 
    };

    //console.log("Outlet Void KOT/BOT - ", outletStallId, data.stallId, payload);
    
    //----- plz uncomment this API when successfully connecting KOT/BOT printers ready to catch the API request (to Sachika/Dulaj/Sandalu).
    response = axios.post(`http://124.43.16.238:5015/api/print`, payload, {headers: { "Content-Type": "application/json" }});

    return response?.data;

  } catch (error) {
    console.error("Error sending Outlet KOT bill:", error.message);
    throw new Error(`Failed to send Outlet KOT bill: ${error.message}`);
  }
};

const sendOutletVoidBOTBill = async (data,voidFrom) => {
   
  let response; 

  const outletStallId = localStorage.getItem("stallID");
   
  try {
     
    const payload = {
      command: "BOT",
      stallId: data.stallId,
      kot_bot_from: outletStallId,
      transactionID: data.transactionID.toString().padStart(10, "0"), 
      void_no: voidFrom, 
      date: new Date(data.createdDateTime).toLocaleString({timeZone: "Asia/Colombo",year: "numeric",month: "2-digit",day: "2-digit"}).split(",")[0],
      time: new Date(data.createdDateTime).toLocaleString("en-US", {timeZone: "Asia/Colombo",hour: "2-digit",minute: "2-digit",second: "2-digit",hour12: false}),
      table_no: data.tableIds, 
      transactionItems: data.transactionItems.map((item) => ({
        itemName: item.itemName,
        itemPrice: item.itemPrice,
        quantity: item.quantity,
        amount: item.itemPrice * item.quantity,
      })), 
    };

    //console.log("Outlet Void KOT/BOT - ", outletStallId, data.stallId, payload);
    
    //----- plz uncomment this API when successfully connecting KOT/BOT printers ready to catch the API request (to Sachika/Dulaj/Sandalu).
    response = axios.post(`http://124.43.16.238:5015/api/print`, payload, {headers: { "Content-Type": "application/json" }});

    return response?.data;

  } catch (error) {
    console.error("Error sending Outlet KOT bill:", error.message);
    throw new Error(`Failed to send Outlet KOT bill: ${error.message}`);
  }
};

const sendCashierExitData = async (exitDetails) => {
  try {
    if (!exitDetails || typeof exitDetails !== "object") {
      throw new Error("Invalid exitDetails: Expected an object");
    }

    const formatDate = (date) => {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      return new Date(date).toLocaleString("en-US", options);
    };

    const loginTime = typeof exitDetails.loginTime === "string"
      ? formatDate(exitDetails.loginTime)
      : formatDate(new Date(exitDetails.loginTime).toISOString());

    const logoutTime = exitDetails.logoutTime instanceof Date
      ? formatDate(exitDetails.logoutTime.toISOString())
      : formatDate(exitDetails.logoutTime);

    if (typeof loginTime !== "string") {
      throw new Error(`Invalid loginTime: ${exitDetails.loginTime}`);
    }

    if (typeof logoutTime !== "string") {
      throw new Error(`Invalid logoutTime: ${exitDetails.logoutTime}`);
    }
    const displayName = localStorage.getItem("displayName") || "Unknown";
    
    const payload = {
      command: "CASHIER_EXIT",
      cashierName: displayName.split(" ")[0],
      stallId: exitDetails.stallID,
      cashTransactions: exitDetails.cashTransactions,
      cardTransactions: exitDetails.cardTransactions,
      commercialTransactions: exitDetails.commercialTransactions,
      sampathTransactions: exitDetails.sampathTransactions,
      amexTransactions: exitDetails.amexTransactions,
      giftVoucherTransactions: exitDetails.giftVoucherTransactions,
      dfccTransactions: exitDetails.dfccTransactions,
      cashInHand: exitDetails.cashInHand,
      floatValue: exitDetails.floatValue,
      loginTime: loginTime,
      logoutTime: logoutTime,
    };

    console.log("Payload to be sent:", payload);

     const response = await axios.post(`http://124.43.16.238:5015/api/print`, payload, {
       headers: { "Content-Type": "application/json" },
     });

    //console.log("Response received:");
    return true;
  } catch (error) {
    console.error("Error sending cashier exit data:", error.message);
    console.error("Failed exitDetails:", exitDetails);
    throw new Error(`Failed to send cashier exit data: ${error.message}`);
  }
};


    return {
      sendKOTBill,
      sendFullBill,
      handleSaveCard,
      sendVoidKOTBill,
      sendFloatValue,
      handleGateControlCardWithVoid,
      handleGateControlCard,
      sendOutletKOTBill,
      sendOutletVoidKOTBill,
      sendCashierExitData,
      sendOutletBOTBill,
      sendOutletVoidBOTBill,
    };
}
export default printService;