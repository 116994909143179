import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import dishesServices from "../../../api/DishesServices/disheseService";

const CustomerContactModal = ({ show, onClose, onSuccess, transactionID, successMessage }) => {
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const validateInputs = () => {
    const newErrors = {};

    if (!customerName) {
      newErrors.customerName = "Customer name is required.";
    } else if (customerName.length > 50) {
      newErrors.customerName = "Name must not exceed 50 characters.";
    }

    if (!customerPhone) {
      newErrors.customerPhone = "Phone number is required.";
    } else if (customerPhone.length !== 10) {
      newErrors.customerPhone = "Phone number must be exactly 10 digits.";
    }

    return newErrors;
  };

  const handleCustomerDetailsSubmit = async () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await dishesServices().postCustomerContact(
        transactionID,
        customerName,
        customerPhone
      );

      if (response.status === 200) {
        setCustomerName("");
        setCustomerPhone("");
        setErrors({});
        onSuccess();
        onClose();
      }
    } catch (error) {
      console.error("Error saving customer details:", error);
      alert("Failed to save customer details.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" centered size="lg">
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: "flex", alignItems: "center" }}>
            {successMessage && (
              <>
                <FaCheckCircle size={28} style={{ color: "green", marginRight: "12px" }} />
                <span style={{ fontSize: "1.6rem", fontWeight: "600" }}>{successMessage}</span>
              </>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label
            htmlFor="customerName"
            style={{
              fontWeight: "600",
              fontSize: "1.2rem",
              color: "#333",
              marginBottom: "8px",
              display: "block",
            }}
          >
            Customer Name:
          </label>
          <input
            type="text"
            id="customerName"
            className="form-control"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            maxLength="50"
            style={{
              height: "48px",
              fontSize: "1rem",
              borderRadius: "8px",
              border: "1px solid #ccc",
              padding: "10px",
            }}
          />
          {errors.customerName && (
            <div style={{ color: "red", fontSize: "0.9rem", marginTop: "5px" }}>
              {errors.customerName}
            </div>
          )}
        </div>
        <div className="form-group mt-4">
          <label
            htmlFor="customerPhone"
            style={{
              fontWeight: "600",
              fontSize: "1.2rem",
              color: "#333",
              marginBottom: "8px",
              display: "block",
            }}
          >
            Phone Number:
          </label>
          <input
            type="text"
            id="customerPhone"
            className="form-control"
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e.target.value.replace(/\D/g, ""))}
            maxLength="10"
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) e.preventDefault();
            }}
            style={{
              height: "48px",
              fontSize: "1rem",
              borderRadius: "8px",
              border: "1px solid #ccc",
              padding: "10px",
            }}
          />
          {errors.customerPhone && (
            <div style={{ color: "red", fontSize: "0.9rem", marginTop: "5px" }}>
              {errors.customerPhone}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button
          variant="secondary"
          onClick={onClose}
          disabled={isSubmitting}
          style={{
            fontSize: "1.1rem",
            padding: "10px 20px",
            borderRadius: "8px",
            border: "none",
          }}
        >
          Cancel
        </Button> */}
        <Button
          variant="primary"
          onClick={handleCustomerDetailsSubmit}
          disabled={isSubmitting}
          style={{
            fontSize: "1.1rem",
            padding: "10px 24px",
            borderRadius: "8px",
          }}
        >
          {isSubmitting ? "Saving..." : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerContactModal;
