import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import dishesServices from "../../../api/DishesServices/disheseService";
import Swal from 'sweetalert2';

const ReprintManagerAccessModal = ({ show, onHide, onSuccess }) => {
  const initialState = {
    username: "",
    password: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: "" }));
    }
  };

  const handleAccountantValidation = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsSubmitting(true);

    const { username, password } = formData;
    if (!username.trim() || !password) {
      setErrors({
        username: !username.trim() ? "Username is required" : "",
        password: !password ? "Password is required" : "",
      });
      setIsSubmitting(false);
      return;
    }

    try {
      const validationResult = await dishesServices().validAccountant(
        username,
        password
      );

      if (!validationResult.success) {
        Swal.fire("Error", "Invalid username or password", "error");
        setIsSubmitting(false);
        return;
      }

      onSuccess();
      onHide();
      setFormData(initialState);

    } catch (error) {
      Swal.fire("Error", "An error occurred. Please try again.", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Accountant Authentication Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleAccountantValidation}>
          <div className="form-group mb-3">
            <label htmlFor="username">Accountant Username:</label>
            <input
              id="username"
              name="username"
              type="text"
              value={formData.username}
              onChange={handleInputChange}
              className={`form-control ${errors.username ? 'is-invalid' : ''}`}
              disabled={isSubmitting}
            />
            {errors.username && (
              <div className="invalid-feedback">{errors.username}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label htmlFor="password">Accountant Password:</label>
            <input
              id="password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              className={`form-control ${errors.password ? 'is-invalid' : ''}`}
              disabled={isSubmitting}
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password}</div>
            )}
          </div>

          <div className="d-flex justify-content-end gap-2">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onHide}
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Validating..." : "Submit"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ReprintManagerAccessModal;