import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import dishesServices from "../../api/DishesServices/disheseService.js";
import printService from "../../api/PrintServices/printService.js";
import Swal from "sweetalert2";
import { toast, ToastContainer } from 'react-toastify';
import CenteredModal from "./customerOder/CenteredModal.js";
import PosHeader from '../../components/topbar/posHeader.jsx';
import { StallIds, OrderStatuses } from "../../enums/stallIds.js";

function DayEnd() {
  const [summaryData, setSummaryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [exitedRows, setExitedRows] = useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const moment = require("moment");

  const fetchSummaryData = async () => {
    try {
      const response = await dishesServices().getFilteredOrders(StallIds, OrderStatuses.IN_PROGRESS);
      const filteredOrders = response.filter((order) => StallIds.includes(order.stallId));
      console.log("filteredOrders: ", filteredOrders);
      const groupedData = filteredOrders.reduce((acc, curr) => {
        const { rfidCardId, cardId, transactionID, totalAmount, tStamp, stallId } = curr;

        if (!acc[cardId]) {
          acc[cardId] = {
            card: cardId,
            kcc_Id: rfidCardId,
            orders: [],
          };
        }

        acc[cardId].orders.push({
          OrderID: transactionID,
          totalAmount: totalAmount,
          tStamp: tStamp,
          stallId: stallId
        });

        return acc;
      }, {});

      const processedData = Object.values(groupedData).flatMap((group) =>
        group.orders.map((order) => ({
          card: group.card,
          kcc_Id: group.kcc_Id,
          OrderID: order.OrderID,
          totalAmount: order.totalAmount,
          tStamp: order.tStamp,
          stallId: order.stallId
        }))
      );

      setSummaryData(processedData);
      setFilteredData(processedData);
    } catch (error) {
      console.error("Error fetching summary data:", error);}
  };

  useEffect(() => {
    fetchSummaryData();
  }, []);


  useEffect(() => {
    if (searchTerm === "") {
      setFilteredData(summaryData);
    } else {
      const lowercasedSearch = searchTerm.toLowerCase();
      const filtered = summaryData.filter(
        (data) =>
          data.OrderID.toString().toLowerCase().includes(lowercasedSearch) ||
          data.card.toString().toLowerCase().includes(lowercasedSearch) ||
          data.stallId.toString().toLowerCase().includes(lowercasedSearch)
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, summaryData]);

  const onUnsettle = async (card) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: `This will mark all orders for card ${card} as UNSETTLE.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unsettle them!",
      cancelButtonText: "No, cancel",
    });
  
    if (!confirmation.isConfirmed) return;
  
    try {
      await dishesServices().handleUnsettle(card);
  
      Swal.fire({
        title: "Success!",
        text: `All orders for card ${card} marked as UNSETTLE.`,
        icon: "success",
        timer: 2000, 
        showConfirmButton: false,
      });
  
      if (card !== 0) {
        await printService().handleSaveCard("Active", [card]);
        //await printService().handleGateControlCard("Active", [card]);
      }
  
      await fetchSummaryData();
    } catch (error) {
      console.error("Error updating order status:", error);
      Swal.fire("Failed to update orders. Please try again.");
    }
  };

  const formatCurrency = (amount) => {
    if (isNaN(amount) || amount === null || amount === undefined) {
      return "0.00";
    }
    return `${amount.toFixed(2).toLocaleString()}`;
  };

  const formatTime = (amount) => {
    if (!amount) return "Invalid Time";
    return moment(amount).format("YYYY-MM-DD HH:mm:ss");
  };

  return (
    <>
<CenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
		<ToastContainer />
    
    <div className={'pos pos-vertical'  }>
    <div className="pos-container">
    <div >
				<PosHeader />
        <div className="nav-container">
			
    <div>
      <style>
        {`
          .table-container {
              padding: 20px;
              background-color: rgba(255, 255, 255, 0.8);
              backdrop-filter: blur(10px);
              border-radius: 8px;
              box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          }

          .table {
              width: 100%;
              border-collapse: collapse;
              background: transparent;
          }

          .table th,
          .table td {
              padding: 12px;
              text-align: left;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          .table th {
              background-color: rgba(240, 240, 240, 0.5);
          }

          .table-row:hover {
              background-color: rgba(230, 230, 230, 0.3);
          }

          .exit-button {
              background-color: #f44336; /* Red */
              color: white;
              border: none;
              padding: 8px 12px;
              border-radius: 4px;
              cursor: pointer;
          }

          .exit-button:hover {
              background-color: #d32f2f; /* Darker red on hover */
          }

          .exited-button {
              background-color: #4caf50; /* Green */
              color: white;
              border: none;
              padding: 8px 12px;
              border-radius: 4px;
              cursor: not-allowed;
          }

          .search-input {
              margin-bottom: 10px;
              padding: 10px;
              width: 100%;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-size: 16px;
          }
        `}
                </style>
                <div className="table-container">
                  <h2>Day End Summary</h2>
                  <br></br>
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search by Order ID, Card or Stall"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <PerfectScrollbar >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Order ID</th>
                          <th>KCC ID</th>
                          <th>Card</th>
                          <th>Amount(RS)</th>
                          <th>Time</th>
                          <th>Stall</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData
                          .sort((a, b) => b.OrderID - a.OrderID)
                          .map((data, index) => (
                            <tr key={index} className="table-row">
                              <td>{data.OrderID}</td>
                              <td>{data.kcc_Id}</td>
                              <td>{data.card}</td>
                              <td style={{ textAlign: "left" }}>{formatCurrency(data.totalAmount)}</td>
                              <td>{formatTime(new Date(data.tStamp))}</td>
                              <td>{data.stallId}</td>
                              <td>
                                {exitedRows[data.card] ? (
                                  <button className="exited-button" disabled>
                                    Settled
                                  </button>
                                ) : (
                                  <button
                                    className="exit-button"
                                    onClick={() => onUnsettle(data.card)}
                                  >
                                    Not Settled
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DayEnd;
