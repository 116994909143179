import React, { useEffect, useContext, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, useNavigate } from "react-router-dom";
import { AppSettings } from '../../../config/app-settings.js';
import dishesServices from '../../../api/DishesServices/disheseService.js'; 
import taxServices from '../../../api/TaxServices/taxServices.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OutletMultiPaymentModal from "./OutletMultiPaymentModal.js";
import PosHeader from '../../../components/topbar/posHeader.jsx';
import CenteredModalFloat from "./CenteredModalFloat.js";
import Swal from 'sweetalert2'; 
import OutletExicutiveModal from "./OutletExicutiveModal.js";
import executiveService from "../../../api/ExecutiveServices/executiveService.js";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import printService from "../../../api/PrintServices/printService.js";
import OutletReprintModal from "./OutletReprintModal.js";
import ReprintManagerAccessModal from './ReprintManagerAcsessModal.js';

function PosOutletCounterCheckout() {

	const location = useLocation();
	const context = useContext(AppSettings);
	const navigate = useNavigate();
	 
	const [filteredTableData, setFilteredTableData] = useState([]);
	const [selectedTable, setSelectedTable] = useState([]);
	const [Taxes, setTaxes] = useState([]);  
	const [executiveList, setExecutiveList] = useState([]);
	const [stallId, setStallId] = useState([]);
	const [outletTables, setOutletTables] = useState([]);

	const [posMobileSidebarToggled, setPosMobileSidebarToggled] = useState(false);  
	const [isTakeawayData, setIsTakeawayData] = useState(false);  
	const [showModal, setShowModal] = React.useState(false);
	const [modalShow_2, setModalShow_2] = React.useState(false);  
	const [modalShow_4, setModalShow_4] = React.useState(false); 
	const [modalShow_5, setModalShow_5] = React.useState(false);
	const [isTakeawayBillData, setIsTakeawayBillData] = useState(false); 
	const [isLoading, setIsLoading] = useState(false);
	const [isBillPrinted, setIsBillPrinted] = useState(false);

	const [searchTerm, setSearchTerm] = useState(''); 
	const [paymentMethod, setPaymentMethod] = useState(''); 
	const [selectedExecutiveType, setSelectedExecutiveType] = useState("");
	const [note, setNote] = useState("");
	const [selectedTableId, setSelectedTableId] = useState("");

	const [notification, setNotification] = useState({ message: '', show: false, type: '' });
	const takeawayData = JSON.parse(localStorage.getItem("TAKEAWAY") || "{}");
	
	const options = outletTables.filter((table) => table.stallId === stallId);

	const [showManagerAccessModal, setShowManagerAccessModal] = useState(false);
    var stal_lId = localStorage.getItem("stallID");

	let TotalTaxesPrice = 0;
	let cardcount = 0;
	var taxTotals = [];
	var cardList= [];
	var orders = [];


	function toggleMobileSidebar(event, table) {
		event.preventDefault();
		setPosMobileSidebarToggled(true);
	}
	
	function dismissMobileSidebar(event) {
		event.preventDefault();
		setPosMobileSidebarToggled(false);
		setSelectedTable([]);
	}
	
	var getSubTotalPrice = () => {
		var  subtotal = 0;
		if(selectedTable){
		selectedTable.forEach( (sub) => {
			subtotal += sub.subTotal

		});
		return subtotal.toFixed(2) || 0;
	}}
	
	var getTotalPrice = () =>  {
		var  total = 0;
		if(selectedTable){
		selectedTable.forEach( (tot) => {
			total += tot.totalAmount

		});
		return total.toFixed(2) || 0;
	}}
	
	var getTaxesPrice = (taxCode) => {
		var TaxesPrice = 0;
		var taxPrese = 0;
		var taxItemId ='';
	
		if (selectedTable) {
			selectedTable.forEach((sele) => {
				if (sele.transactionItems) {
					sele.transactionItems.forEach((order) => {
						if (order.taxItems) {
							order.taxItems.forEach((tax) => {
								if (tax.taxCode === taxCode) {
									TaxesPrice += (tax.taxValue * order.quantity);
									taxPrese = tax.taxPercentage;
									taxItemId = tax.taxItemId;

								}
							});
						}
					});
				}
			});
			
			TotalTaxesPrice += TaxesPrice;
	
			let existingTax = taxTotals.find(t => t.taxCode === taxCode); 
			if (existingTax) {
				existingTax.taxTotal = TaxesPrice.toFixed(2);
			} else {
				if (taxCode && TaxesPrice) {
					taxTotals.push({
						taxCode: taxCode,
						taxPrese: taxPrese,
						taxTotal: TaxesPrice.toFixed(2),
						taxListId: taxItemId 
					});
				}
			}
			
			return TaxesPrice.toFixed(2);
		}
		
		return '0.00';
	};
	
	function getTransactionIds() {
		var TransactionIdList = [];

		if (selectedTable) {
		selectedTable.forEach((sub) => {
			TransactionIdList.push(sub.transactionID);
			if (!cardList.includes(sub.cardId)) {
			cardList.push(sub.cardId);
			}
		});
		}
		return TransactionIdList;
  	}

	const removeTable = (tableId) => {
		setSelectedTableId("");
		const updatedTableData = selectedTable.filter(table => table.tableIds !== tableId);
		setSelectedTable(updatedTableData); 
	};

	const finish = async () => {
		try {
				setSelectedTable([]);
				setSearchTerm('');
				cardList = [];
				orders = []; 
				setSelectedTableId("");
				setIsBillPrinted(false)
		} catch (error) {
				console.error("ERROR");
		}
	};

	const tableDataClear = async () => {
		try {
				const result = await Swal.fire({
					title: 'Are you sure?',
					text: "Do you really want to clear all data?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, clear it!',
					cancelButtonText: 'No, keep it',
					confirmButtonColor: '#d33',
					cancelButtonColor: '#3085d6',
				});
				
				if (result.isConfirmed) {
					setSelectedTable([]);
					setSearchTerm(''); 
					cardList = [];
					orders = []; 
					setSelectedTableId("");
				}
			} catch (error) {
				console.error("ERROR");
			}
	};

	/*const addMultipleToSelectedTable = (newItems) => {
		setSelectedTable((prevTable) => {
		const uniqueNewItems = newItems.filter((newItem) => {
				return !prevTable.some((existingItem) => existingItem.transactionID === newItem.transactionID);
		});
		 
		return [...prevTable, ...uniqueNewItems];
		});
		setSelectedTableId("");
	};*/

	const addMultipleToSelectedTable = (newItems) => {
		setSelectedTable((prevTable) => {
	
		  const prevTableArray = Array.isArray(prevTable) ? prevTable : [];
		  
		  const uniqueNewItems = newItems.filter((newItem) => {
			return !prevTableArray.some((existingItem) => existingItem.transactionID === newItem.transactionID);
		  });
		  
		  return [...prevTableArray, ...uniqueNewItems];
		});
		setSelectedTableId("");
	  };

	const handleTableChange = (event, selectedOption) => {
		setSelectedTableId(selectedOption?.tableId || "");   
	}; 

	const handleVoidBill = () => {
		
		const tableIds = [...new Set(selectedTable.map((table) => table.tableIds))];
		
		const hasPrintedStatus = selectedTable.some((table) => table.statusChange === "PRINTED");

		if (isBillPrinted || hasPrintedStatus) {
			Swal.fire({
			title: "Warning",
			text: "This dining table order already has a submitted bill. Please verify the order before proceeding.",
			icon: "warning",
			confirmButtonText: "OK",
			});
			return;
		}

		if (tableIds.length > 1 && !tableIds.includes("NO")) {
			Swal.fire({
			title: "Warning",
			text: "You are attempting to void orders from multiple dining tables. Please ensure you select only one dining table to proceed.",
			icon: "warning",
			confirmButtonText: "OK",
			});
			return;
		}

		const voidBillState = {
			selectedTable,
			fromOngoingOrders: true,
		};
		localStorage.setItem("voidbill", JSON.stringify(selectedTable));

		navigate(`/pos/customer-order-outlet?-void`, { state: voidBillState });
	};

	const printOutletColectiveData = async (billData) => {
		var cashAmount = 0;
		var cardAmount = 0;
		var giftCardNo = "";
		if(stal_lId ==='BAKESNCAKE' || stal_lId ==='CAFEWALK')
			{
				var gross_Total = getTotalPrice();
				var sub_Total = getTotalPrice();
			}
			else
			{
			 
				var gross_Total = parseFloat(getSubTotalPrice());
				var sub_Total = parseFloat(getSubTotalPrice());
			  
			  
			}
		
		var totalPrice = getTotalPrice();
		var taxTotal = Taxes && Taxes.map((tax, index) => getTaxesPrice(tax.taxCode));
		var vatTotal = taxTotal;
		var transactionId = getTransactionIds();

		const hasPrintedStatus = selectedTable.some((table) => table.statusChange === "PRINTED");

		if (isBillPrinted || hasPrintedStatus) {
			setIsLoading(false);
			Swal.fire({
			title: "Warning",
			text: "This dining table order already has a submitted bill. Please verify the order before proceeding.",
			icon: "warning",
			confirmButtonText: "OK",
			});
			return;
		}

		try {
			const response = await dishesServices().postOutletColectiveData(
			gross_Total,
			sub_Total,
			totalPrice,
			cashAmount,
			cardAmount,
			"OUTLET-PRINTED",
			"",
			"",
			0,
			parseInt(taxTotal[0]),
			parseInt(vatTotal[0]),
			false,
			transactionId,
			selectedExecutiveType,
			"",
			giftCardNo,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			0,
			note
			);

			setIsBillPrinted(true);
			setTimeout(() => {
			setIsLoading(false);
			}, 1500);

			if (response) {
			try {
				printService().sendFullBill(response);
			} catch (error) {
				console.error("Failed to print full bill:", error);
			}
			}
		} catch (error) {
			setIsLoading(false);
 
			if (error.message.includes("Mismatch in TotalBill")) {
			Swal.fire({
				title: "Error",
				text: error.message,
				icon: "error",
				confirmButtonText: "OK",
			});
			} else {
			setNotification({
				message: "Bill failed. Please try again.",
				show: true,
				type: "error",
			});
			}
		}
	};

	const fetchTaxes = async () => {
		try {
		  const response = await taxServices().getAllTax();
		  setTaxes(response.data); 
		  
		} catch (error) {
		  console.error('Error fetching suppliers data:', error);
		}
	};

	const fetchExecutiveData = async () => {
		try {
		  const response = await executiveService().getAllExecutiveList();
		   
		  setExecutiveList(response); 
		  
		} catch (error) {
		  console.error('Error fetching suppliers data:', error);
		}
	};

	const fetchTables = async () => {
		try {
			const response = await dishesServices().getAllTables();
			setOutletTables(response.data); 
				
		} catch (error) {
				console.error('Error fetching suppliers data:', error);
		}
	};

	const fetchTableOrders = async (tableId) => {

		const hasPrintedStatus = selectedTable.some((table) => table.statusChange === "PRINTED");
		
		if (isBillPrinted || hasPrintedStatus) {
			Swal.fire({
				title: "Warning",
				text: "There are pending orders associated with a submitted bill for this dining table. Please ensure the bill is settled before adding new orders, or close all table cards linked to the submitted bill.",
				icon: "warning",
				confirmButtonText: "OK",
			});
			return;  
		}
	  
		if (!tableId) {
				alert("Please select a table first.");
				return;
		}
		try {
				const response = await dishesServices().getAllTableOreders(tableId);
				setFilteredTableData(response);  
				
		} catch (error) {
				console.error("An error occurred while fetching data for table:", tableId, error);
		}

	};

	useEffect(() => { 
		if (Object.keys(takeawayData).length === 0) {
			setIsTakeawayData(false);
			setFilteredTableData([]);   
			setSelectedTable([]);   
		} 
	}, [JSON.stringify(takeawayData)]);

	useEffect(() => {
		setIsTakeawayBillData(location.state?.fromTakeawayOrders)
		const fetchTakeawayData = async () => {
			 
			if (takeawayData && (location.state?.fromTakeawayOrders)) {
				setIsTakeawayData(true);
				setFilteredTableData([]);
				setFilteredTableData(takeawayData);
				setSelectedTable(takeawayData);
			}
		};
		fetchTakeawayData();
  	}, [location.state]);
 
	useEffect(() => {
		if (selectedTable) {
		selectedTable.forEach((transa) => {
			if (transa && transa.transactionItems) {
			transa.transactionItems.forEach((order) => {
				orders.push(order);
			});
			}
		});
		}
		localStorage.setItem('orderData', JSON.stringify(orders));
	}, [selectedTable]);

	useEffect(() => {
		if (selectedTable.length <= 0) {
		 setIsBillPrinted(false);
		} 
	}, [selectedTable]);

	useEffect(() => {
			addMultipleToSelectedTable(filteredTableData);
	}, [filteredTableData]);

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentFullHeight(true);
		context.setAppContentClass('p-0');
		setStallId(localStorage.getItem('stallID'));
		
		fetchTaxes();
		fetchExecutiveData();
		fetchTables(); 

		const floatValue = localStorage.getItem("floatValue") ? true : false;
		if (floatValue) { 
			setShowModal(true);
		}
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentFullHeight(false);
			context.setAppContentClass('');
		};
	}, 
	[]);

	useEffect(() => {
		const handleDiningData = async () => {
		  if (location.state?.fromTableOrders && location.state.selectedTable) {
			const tableOrders = location.state.selectedTable;
			
			if (tableOrders && tableOrders.length > 0) {
			  setFilteredTableData(tableOrders);
			  setSelectedTable(tableOrders);
			  if (location.state.selectedTableId) {
				setSelectedTableId(location.state.selectedTableId);
			  } else if (tableOrders[0].TableIds || tableOrders[0].tableIds) {
				setSelectedTableId(tableOrders[0].TableIds || tableOrders[0].tableIds);
			  }
			}
		  }
		};
	  
		handleDiningData();
	}, [location.state]);

	useEffect(() => {
		const handleOrderData = async () => {
		  if (location.state?.fromTableOrders || location.state?.fromTakeawayOrders) {
			const orderData = location.state.selectedTable;
			
			if (orderData && orderData.length > 0) {
			  setFilteredTableData(orderData);
			  setSelectedTable(orderData);
			  
			  if (location.state.selectedTableId) {
				setSelectedTableId(location.state.selectedTableId);
			  } else if (orderData[0].tableIds) {
				setSelectedTableId(orderData[0].tableIds);
			  }
	  
			  if (location.state.fromTakeawayOrders || location.state.isTakeaway) {
				setIsTakeawayData(true);
			  } else {
				setIsTakeawayData(false);
			  }
			}
		  }
		};
		
		handleOrderData();
	  }, [location.state]);
	
	return (
		<>
		<CenteredModalFloat
			show={showModal}
			onHide={() => setShowModal(false)}
			onSubmit={(amount) => {
				setShowModal(false);  
			}}
		/> 
		<ToastContainer />
		<div className={'pos pos-vertical pos-with-header pos-with-sidebar ' + ((posMobileSidebarToggled) ? 'pos-mobile-sidebar-toggled' : '')} id="pos">
			<div className="pos-container">
			<div className="pos-header fixed-header">
				<PosHeader />
			</div>
				
				<div className="pos-content">
					<div className="pos">
						<div className="pos-container">
							
							<div className="pos-content h-100">
								<div className="pos-content-container p-3 h-100">
									{isLoading && (
										<div
											style={{
											position: 'fixed',
											top: 0,
											left: 0,
											width: '100vw',
											height: '100vh',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											backgroundColor: 'rgba(0, 0, 0, 0.5)',
											zIndex: 1000,
											}}
										>
											<div role="status">
											<span
												style={{
												color: '#F57C00',
												fontSize: '1.5rem',
												fontWeight: 'bold',
												}}
											>
												Submitting bill, please wait...
											</span>
											</div>
										</div>
									)}

								<div className="row gx-3">
								{!(takeawayData && isTakeawayData) && selectedTable && selectedTable.length > 0 ? (
									[...new Map(selectedTable.map(item => [item.tableIds, item])).values()]
									.map((table, index) => (
										<div className="col-12 pb-2 d-flex justify-content" key={`${table.tableIds}-${index}`}>
										<div
											className={'pos-checkout-table' +
											((selectedTable && table.transactionID === selectedTable.transactionID) ? ' selected' : '') +
											((!table.orders && table.status !== 'Reserved') ? ' available' : '') +
											((table.orders) ? ' in-use' : '') +
											((table.status === 'Reserved') ? ' disabled' : '')
											}
										>
										<a href="#/" className="pos-checkout-table-container" onClick={(event) => toggleMobileSidebar(event, table)}>
											<div className="pos-checkout-table-header">
											<button 
											className="btn btn-danger btn-sm" 
											onClick={() => removeTable(table.tableIds)}
											style={{top: '10px', right: '10px'}}
										>
											X
										</button>
											
											<div className="fw-bold fs-1">{table.tableIds}</div>
											<div className="fs-13px text-body text-opacity-50">
												{table.transactionItems && (<span>{selectedTable.length} orders</span>)}
											</div>
											</div>
										</a>
										</div>
									</div>
									))
								) : (
									<div className="col-12">
									No records found
									</div>
								)}
								</div>
								</div>
							</div>
			
							<div className="pos-sidebar" id="pos-sidebar" style={{ width: '800px'}}>

								<div className="pos-sidebar-header" >
									<div className="back-btn">
										<button type="button" onClick={dismissMobileSidebar} className="btn">
											<i className="fa fa-chevron-left"></i>
										</button>
									</div>
									
									<div className="col-12 mb-3 d-flex align-items-center">
										<div className="d-flex align-items-center justify-content-center" style={{ color: '#F57C00', fontSize: '28px', fontWeight: 'bold' }}>
											{(takeawayData && isTakeawayData) ? "TAKEAWAY" : "DINING"}
										</div>
										{(!(takeawayData && isTakeawayData)) && (
											<>
											<Autocomplete
												options={options}
												getOptionLabel={(option) => option.tableId}
												value={options.find((option) => option.tableId === selectedTableId) || null} 
												onChange={handleTableChange}  
												renderInput={(params) => (
												<TextField
													{...params}
													label="Select orders assigned table"
													InputLabelProps={{
													style: { color: "rgba(0, 0, 0, 0.38)", fontWeight: "bold" },
													}}
													InputProps={{
													...params.InputProps,
													style: { borderColor: "#F57C00" },
													}}
												/>
												)}
												sx={{
												width: "100%",
												marginBottom: "5px",
												marginLeft: "30px",
												"& .MuiOutlinedInput-root": {
													"& fieldset": {
													borderColor: "#F57C00",
													},
													"&:hover fieldset": {
													borderColor: "#F57C00",
													},
													"&.Mui-focused fieldset": {
													borderColor: "#F57C00",
													},
												},
												"& .MuiAutocomplete-clearIndicator": {
													color: "#F57C00",
												},
												"& .MuiAutocomplete-popupIndicator": {
													color: "#F57C00",
												},
												}}
											/>
											<button disabled={ (selectedTableId === "")} className="btn btn-primary ms-3" onClick={() => fetchTableOrders(selectedTableId)}>
												ADD
											</button>
											<button disabled={ (selectedTableId === "" && selectedTable.length === 0)} className="btn btn-primary ms-3" onClick={tableDataClear}>
												CLEAR
											</button>
											</>

										)}
									
									</div>
								</div>

								<hr className="m-0 opacity-1" />

								<PerfectScrollbar className="pos-sidebar-body">

								<div>
									{selectedTable ? (
										selectedTable.map((transa, transaIndex) =>
										transa && transa.transactionItems ? (
											transa.transactionItems.map((order, orderIndex) => {
											let totalAddOnsPrice = 0;
											
											return (
												<div className="pos-order py-3" key={orderIndex}>
												<div className="pos-order-product">
													<div className="flex-1">
													<div className="row">
														<div className="col-8">
														<div className="fs-6 fw-semibold">{transa.stallId} - {order.itemName}</div>

														{order.addOns && order.addOns.map((addOn, addOnIndex) => {
															const addOnPrice = addOn.price || 0;
															totalAddOnsPrice += addOnPrice;

															return (
															<div key={addOnIndex}>
																- {addOn.key}: {addOn.addOn} - RS {addOnPrice.toFixed(2)}
															</div>
															);
														})}
														</div>
														<div className="col-1">x{order.quantity}</div>
														<div className="col-3 text-body fw-semibold text-end">
														RS {((parseFloat(order.itemPrice) + totalAddOnsPrice) * order.quantity).toFixed(2)}
														</div>
													</div>
													</div>
												</div>
												</div>
											);
											})
										) : (
											<div className="pos-order py-3" key={transaIndex}>
											No records found
											</div>
										)
										)
									) : (
										<div className="pos-order py-3">
										No records found
										</div>
									)}
								</div>

								</PerfectScrollbar>
								
								<div className="pos-sidebar-footer"> 
								
								<div className="d-flex align-items-center mb-2">
									<div>Subtotal</div>
									<div className="flex-1 text-end h6 mb-0">RS {getSubTotalPrice()}</div>
								</div>
								<div className="small mb-2">
									{Taxes && Taxes.map((tax, index) => (
									<div key={index} className="d-flex justify-content-between">
										<div>{tax.taxCode}: {tax.taxRate} %</div>
										<div className="flex-1 text-end h6 mb-0">RS {getTaxesPrice(tax.taxCode)}</div>
									</div>
									))}
								</div>
								<hr className="opacity-1 my-10px" />
								<div className="d-flex align-items-center mb-2">
									<div>Total</div>
									<div className="flex-1 text-end h4 mb-0">RS {getTotalPrice()}</div>
								</div>
								<div className="mt-3">
									<div className="d-flex justify-content-between">
										<button 
												className="btn btn-default flex-fill w-180px me-10px d-flex align-items-center justify-content-center " 
												//className="btn btn-theme flex-fill d-flex align-items-center justify-content-center" 
												onClick={handleVoidBill}
												disabled={selectedTable.length === 0}
											>
												<span>
												
												<i className="fa fa-receipt fa-fw fa-lg my-30px d-block mx-auto"></i>
												<span className=" fw-semibold   fw-bold">Void Bill</span>
												</span>
										</button>
										{(!(takeawayData && isTakeawayData)) && (

										// {false && (

											<button
												className="btn btn-default flex-fill w-180px me-10px d-flex align-items-center justify-content-center"
												style={{
													backgroundColor: '#333',
													color: '#FFB74D',
													border: '1px solid #FFB74D',
												}}
												disabled={selectedTable.length === 0}
												onClick={(e) => {
													setIsLoading(true);
													e.preventDefault();
													printOutletColectiveData(selectedTable);
												}}
												>
												<span>
													<i className="fa fa-receipt fa-fw fa-lg my-30px d-block"></i>
													<span className="small fw-semibold">Bill</span>
												</span>
											</button>

										)}
									
									<a
										href="#/"
										className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 ${
											selectedTable.length === 0 || (selectedTable.some((table) => table.statusChange === 'INPROGRESS') && !isBillPrinted && !(takeawayData && isTakeawayData))
											? 'disabled'
											: ''
										}`}
										// className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 ${ selectedTable.length === 0 ? 'disabled' : '' }`}
										style={{ width: '40%' }}
										onClick={(e) => {
											const isDisabled =
											selectedTable.length === 0 || (selectedTable.some((table) => table.statusChange === 'INPROGRESS') && !isBillPrinted && !(takeawayData && isTakeawayData));
											if (isDisabled) {
											e.preventDefault();
											return;  
											}
											//const isDisabled = selectedTable.length === 0 ;
											// if (isDisabled) {
											// 	e.preventDefault();
											// 	return;  
											// }
											setModalShow_2(true);
											setPaymentMethod('CASH');
										}}
										>
										<span>
											<span className="small fw-bold" style={{ fontSize: '15px', textAlign: 'center' }}>
											PAY
											</span>
										</span>
									</a>

									<OutletMultiPaymentModal
										show={modalShow_2}
										onHide={() => setModalShow_2(false)}
										grossTotal={parseFloat(getSubTotalPrice())}
										Tax={taxTotals}
										Total={getTotalPrice()}
										paymentMethod={"MULTY"}
										transactionIds={getTransactionIds()}
										finish={finish} 
										selectedExecutiveType={selectedExecutiveType}
										setSelectedExecutiveType={setSelectedExecutiveType}
										note={note}
										setNote={setNote}
										setIsTakeawayData={setIsTakeawayData}
										takeawayData={takeawayData}
										isTakeawayData={isTakeawayData}
										setIsBillPrinted={setIsBillPrinted}
										
									/>
									 
									<a
										href="#/"
										className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 ${
											selectedTable.length === 0 || (selectedTable.some((table) => table.statusChange === 'INPROGRESS') && !isBillPrinted && !(takeawayData && isTakeawayData))
											? 'disabled' 
											: ''
										}`}
										// className={`btn btn-theme flex-fill d-flex align-items-center justify-content-center me-2 ${ selectedTable.length === 0 ? 'disabled' : '' }`}
										style={{  width: '40%' }}
										onClick={(e) => {
											const isDisabled =
											selectedTable.length === 0 || (selectedTable.some((table) => table.statusChange === 'INPROGRESS') && !isBillPrinted && !(takeawayData && isTakeawayData));
											if (isDisabled) {
											e.preventDefault();  
											return;
											}
											// const isDisabled =selectedTable.length === 0 ;
											// if (isDisabled) {
											// 	e.preventDefault();  
											// 	return;
											// }
											setModalShow_4(true);
											setPaymentMethod("EXECUTIVE");
										}}
										>
										<span>
											<span className="small fw-bold" style={{ fontSize: '15px', textAlign: 'center' }}>
											COMPLIMENTARY
											</span>
										</span>
									</a>

									<a
										href="#/"
										className="btn btn-theme flex-fill d-flex align-items-center justify-content-center"
										style={{ backgroundColor: '#0A3981', width: '20%' }}
										onClick={(e) => {
											e.preventDefault();
											setShowManagerAccessModal(true);
										}}
									  >
										<span>
											<span className="small fw-bold" style={{ fontSize: '15px', textAlign: 'center' }}>
											RE PRINT
											</span>
										</span>
									</a>

									<ReprintManagerAccessModal
									show={showManagerAccessModal}
									onHide={() => setShowManagerAccessModal(false)}
									onSuccess={() => {
										setModalShow_5(true);
									}}
									/>

									<OutletReprintModal
										show={modalShow_5}
										onHide={() => setModalShow_5(false)}
										grossTotal={getSubTotalPrice()}
										Tax={taxTotals}
										Total={getTotalPrice()}
										transactionIds={getTransactionIds()}
										selectedExecutiveType={selectedExecutiveType}
										note={note}
									/>
 
									<OutletExicutiveModal
										show={modalShow_4}
										onHide={() => setModalShow_4(false)}
										grossTotal={getSubTotalPrice()}
										Tax={taxTotals}
										Total={getTotalPrice()}
										giftCardNumber={searchTerm}
										transactionIds={getTransactionIds()}
										finish={finish} 
										executiveList={executiveList}
										paymentMethod={paymentMethod}
										selectedExecutiveType={selectedExecutiveType}
										setSelectedExecutiveType={setSelectedExecutiveType}
										note={note}
										setNote={setNote}
										setIsTakeawayData={setIsTakeawayData}
										takeawayData={takeawayData}
										isTakeawayData={isTakeawayData}
										setIsBillPrinted={setIsBillPrinted}
									/>    
									 
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	)
}

export default PosOutletCounterCheckout;