import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import dishesServices from '../../../api/DishesServices/disheseService';
import printService from '../../../api/PrintServices/printService';
import Swal from 'sweetalert2';

const ReprintModal = ({ show, onHide }) => {
  const [collectiveId, setCollectiveId] = useState('');
  const [date, setDate] = useState('');
  const [allTransactions, setAllTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const loggedStallId = localStorage.getItem('stallID');

  useEffect(() => {
    if (show) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      
      setDate(formattedDate);
      setCollectiveId('');
      setAllTransactions([]);
      setFilteredTransactions([]);
    }
  }, [show]);

  useEffect(() => {
    if (date && show) {
      fetchDayTransactions();
    }
  }, [date, show]);

  const handleClose = () => {
    setDate('');
    setCollectiveId('');
    setAllTransactions([]);
    setFilteredTransactions([]);
    onHide && onHide();
  };

  const fetchDayTransactions = async () => {
    setLoading(true);
    try {
      const response = await dishesServices().getAllCashier();
      const searchDate = new Date(date);
      const startTime = new Date(searchDate);
      startTime.setHours(4, 0, 0, 0);
      const endTime = new Date(searchDate);
      endTime.setHours(23, 0, 0, 0);

      const dayTransactions = response.data.filter(ct => {
        const transTime = new Date(ct.createdDateTime).getTime();
        return transTime >= startTime.getTime() && transTime <= endTime.getTime();
      });

      // Filter by logged-in stall ID
      const filtered = dayTransactions.filter(t => t.stallId === loggedStallId)
      .sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));
      setAllTransactions(filtered);
      setFilteredTransactions(filtered);
    } catch (error) {
      console.error('Fetch error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch transaction data'
      });
    }
    setLoading(false);
  };

  const handleFilter = () => {
    let filtered = [...allTransactions];
    if (collectiveId) {
      filtered = filtered.filter(t => 
        t.collectiveTransactionID.toString() === collectiveId
      );
    }
    setFilteredTransactions(filtered);
  };

  const handleReprint = async (transaction) => {
    try {
      if (!transaction) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Invalid transaction data'
        });
        return;
      }
  
      const printData = {
        data: {
          collectiveTransactionId: {
            collectiveTransactionID: transaction.collectiveTransactionID,
            stallId: transaction.stallId,
            employeeID: transaction.employeeID,
            tStamp: transaction.createdDateTime,
            grossTotal: transaction.grossTotal,
            subTotal: transaction.subTotal,
            totalBill: transaction.totalBill,
            taxTotal: transaction.taxTotal,
            vatTotal: transaction.vatTotal,
            discount: transaction.discount || 0,
            cardAmount: transaction.cardAmount,
            cashAmount: transaction.cashAmount,
            transactions: transaction.transactions || [],
          }
        }
      };
  
      await printService().sendFullBill(printData);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: `Bill reprinted: ${transaction.collectiveTransactionID}`,
        timer: 1500,
        showConfirmButton: false
      });
    } catch (error) {
      console.error('Print error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to print receipt'
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton style={{ backgroundColor: '#F57C00', color: 'white' }}>
        <Modal.Title>Reprint Bills</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <label className="form-label">Date</label>
            <input
              type="date"
              className="form-control"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Invoice No</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="ENTER INVOICE NO"
                value={collectiveId}
                onChange={(e) => setCollectiveId(e.target.value)}
              />
              <button 
                className="btn"
                style={{ backgroundColor: '#F57C00', color: 'white' }}
                onClick={handleFilter}
              >
                Search
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <label className="form-label">Stall ID</label>
            <input
              type="text"
              className="form-control"
              value={loggedStallId}
              readOnly
              disabled
            />
          </div>
        </div>

        <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {loading ? (
            <div className="text-center py-4">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <table className="table table-hover table-bordered">
              <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                <tr>
                  <th>INVOICE NO</th>
                  <th>Stall ID</th>
                  <th>Total Bill</th>
                  <th>Payment Method</th>
                  <th>Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions.map((trans) => (
                  <tr key={trans.collectiveTransactionID}>
                    <td>{trans.collectiveTransactionID}</td>
                    <td>{trans.stallId}</td>
                    <td>Rs. {trans.totalBill.toFixed(2)}</td>
                    <td>{trans.paymentMethod}</td>
                    <td>{new Date(trans.createdDateTime).toLocaleTimeString()}</td>
                    <td>
                      <button 
                        className="btn btn-sm"
                        style={{ backgroundColor: '#F57C00', color: 'white' }}
                        onClick={() => handleReprint(trans)}
                      >
                        Reprint
                      </button>
                    </td>
                  </tr>
                ))}
                {filteredTransactions.length === 0 && !loading && (
                  <tr>
                    <td colSpan="6" className="text-center">No transactions found</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>Cancel</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReprintModal;