const StallIds = Object.freeze([
    "WSCHINESE",
    "WSINDIAN",
    "WSITALIAN",
    "WSBEVERAGE",
    "WSDESSERT",
    "WSTHAI",
    "BAKESNCAKE",
    "CAFEWALK",
    "FOODBOOTH",
    "GELETERIA",
    "MONGOLIAN",
    "SRILANKAN",
    "TERRACEBAR",
    "THEFRESH",
  ]);
  
  const OrderStatuses = Object.freeze({
    IN_PROGRESS: "INPROGRESS",
  });
  
  export { StallIds, OrderStatuses };
  