import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import discountService from '../../../api/discountServices/discountService';
import dishesServices from '../../../api/DishesServices/disheseService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import printService from '../../../api/PrintServices/printService';
import taxServices from '../../../api/TaxServices/taxServices';
import CustomerContactModal from './CustomerContactModal';
import Swal from 'sweetalert2';

function MultiPaymentModal(props) {
  const {
    grossTotal,
    Tax,
    Total,
    onHide,
    show,
    paymentMethod,
    transactionIds,
    finish,
    cardList,
    selectedExecutiveType,
    setSelectedExecutiveType,
    note,
    setNote,
  } = props;
  const [cardTypes, setCardTypes] = useState([]);
  const [selectedCardType, setSelectedCardType] = useState('');
  const [cardDiscount, setCardDiscount] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [card, setCard] = useState(false);
  const [stall, setstall] = useState(false);
  const [notification, setNotification] = useState({ message: '', show: false, type: '' });
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [collectiveTransactionID, setCollectiveTransactionID] = useState(null);
  const [numberInput, setNumberInput] = useState(""); 
  const [SelectPaymentMethods, setSelectPaymentMethods] = useState(""); 
  const [isValid, setIsValid] = useState(false);
  
  const [Tax_Total, setTax_Total] = useState(0);
  const [vat_Total, setVat_Total] = useState(0);
  const [discount_Amount, setDiscount_Amount] = useState(0);
  const [Total_Price, setTotalPrice] = useState(0);
  const [gross_Total, setGrossTotal] = useState(0);//----------------------
  const [sub_Total, setSubTotal] = useState(0);//----------------------

  let taxIds = "";
  let discountSequences ="";
 

  const [receivedAmount, setReceivedAmount] = useState(0);
  const [receivedAmountCOMBANK, setReceivedAmountCOMBANK] = useState(0);
  const [receivedAmountSAMPATH, setReceivedAmountSAMPATH] = useState(0);
  const [receivedAmountAMEX, setReceivedAmountAMEX] = useState(0);
  const [receivedAmountGTV, setReceivedAmountGTV] = useState(0);
  const [receivedAmountGTV_NO, setReceivedAmountGTV_NO] = useState(0);
  const [receivedAmountDFCC, setReceivedAmountDFCC] = useState(0);
  const [changeToReturn, setChangeToReturn] = useState(0);
  const [recivedTotalAmount, setRecivedTotalAmount] = useState(0);

  const handleKeypadClick = (value,type) => {
    switch (type) {
      case "CASH":
        setReceivedAmount(prev => prev == '0' ? value.toString() : prev + value.toString()); 
        break;
    
      case "COM":
         setReceivedAmountCOMBANK(prev => prev == '0' ? value.toString() : prev + value.toString());
        break;
    
      case "SAM":
        setReceivedAmountSAMPATH(prev => prev == '0' ? value.toString() : prev + value.toString()); // Replace '0' or append number
        break;
    
      case "AMEX":
        setReceivedAmountAMEX(prev => prev == '0' ? value.toString() : prev + value.toString()); 
        break;
    
      case "GIFT":
        setReceivedAmountGTV(prev => prev == '0' ? value.toString() : prev + value.toString());
        break;

        case "GIFT_NO":
        setReceivedAmountGTV_NO(prev => prev == '0' ? value.toString() : prev + value.toString())
        break;
        
        case "DFCC":
          setReceivedAmountDFCC(prev => prev == '0' ? value.toString() : prev + value.toString())
        break;
    
      default:
        console.error("Invalid payment type");
    };

};

  const clearAmount = (type) => {
    switch (type) {
      case "CASH":
        setReceivedAmount(0);
        break;
    
      case "COM":
        setReceivedAmountCOMBANK(0)
        break;
    
      case "SAM":
        setReceivedAmountSAMPATH(0)
        break;
    
      case "AMEX":
        setReceivedAmountAMEX(0)
        break;
    
      case "GIFT":
        setReceivedAmountGTV(0)
        break;

        case "GIFT_NO":
        setReceivedAmountGTV_NO(0)
        break;

        case "DFCC":
          setReceivedAmountDFCC(0)
        break;
    
      default:
        console.error("Invalid payment type");
    };
  };
  const exactAmount = (type) => {
    switch (type) {
      case "CASH":
        setReceivedAmount(Total_Price);
        break;
    
      case "COM":
        setReceivedAmountCOMBANK(Total_Price)
        break;
    
      case "SAM":
        setReceivedAmountSAMPATH(Total_Price)
        break;
    
      case "AMEX":
        setReceivedAmountAMEX(Total_Price)
        break;
    
      case "GIFT":
        setReceivedAmountGTV(Total_Price)
        break;
        
        case "DFCC":
          setReceivedAmountDFCC(Total_Price)
        break;
    
      default:
        console.error("Invalid payment type");
    };
  };

  const handleIncrementClick = (increment,type) => {
    switch (type) {
      case "CASH":
        setReceivedAmount(prev => (parseFloat(prev) + increment).toString());
        break;
    
      case "COM":
        setReceivedAmountCOMBANK(prev => (parseFloat(prev) + increment).toString())
        break;
    
      case "SAM":
        setReceivedAmountSAMPATH(prev => (parseFloat(prev) + increment).toString())
        break;
    
      case "AMEX":
        setReceivedAmountAMEX(prev => (parseFloat(prev) + increment).toString())
        break;
    
      case "GIFT":
        setReceivedAmountGTV(prev => (parseFloat(prev) + increment).toString())
        break;
        
        case "DFCC":
          setReceivedAmountDFCC(prev => (parseFloat(prev) + increment).toString())
        break;
    
      default:
        console.error("Invalid payment type");
    };
};

  const handleDecimalClick = (type) => {
    switch (type) {
      case "CASH":
        if (!receivedAmount.toString().includes('.')) {
          setReceivedAmount(prev => prev + '.');
      }
        break;
    
      case "COM":
        if (!receivedAmount.toString().includes('.')) {
          setReceivedAmountCOMBANK(prev => prev + '.');
      }
        break;
    
      case "SAM":
        if (!receivedAmount.toString().includes('.')) {
          setReceivedAmountSAMPATH(prev => prev + '.');
      } // Replace '0' or append number
        break;
    
      case "AMEX":
        if (!receivedAmount.toString().includes('.')) {
          setReceivedAmountAMEX(prev => prev + '.'); // Append a decimal if not already present
      }
        break;
    
      case "GIFT":
        if (!receivedAmount.toString().includes('.')) {
          setReceivedAmountGTV(prev => prev + '.'); // Append a decimal if not already present
      }
        break;

        case "GIFT_NO":
          if (!receivedAmount.toString().includes('.')) {
            setReceivedAmountGTV_NO(prev => prev + '.'); // Append a decimal if not already present
        }
        break;
        
        case "DFCC":
          if (!receivedAmount.toString().includes('.')) {
            setReceivedAmountDFCC(prev => prev + '.'); // Append a decimal if not already present
        }
        break;
        
    
      default:
        console.error("Invalid payment type");
    };
    
  };

  const posKeypad = (
    <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns for the number pad (1-9, 0, . and Clear)
        gap: '5px', // Adjust the gap between buttons
        marginTop:"-30px",
    }}>
        {/* Number Pad */}
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
            <Button 
                key={num} 
                onClick={() => handleKeypadClick(num,SelectPaymentMethods)} 
                style={{
                    padding: '20px',
                    fontSize: '1.2em',
                    backgroundColor: '#f0f0f0',
                    color: '#000',
                }}
            >
                {num}
            </Button>
        ))}

        {/* Bottom Row with Zero, Decimal and Clear */}
        <Button 
            onClick={() => handleKeypadClick(0,SelectPaymentMethods)} 
            style={{
                padding: '20px',
                fontSize: '1.2em',
                backgroundColor: '#f0f0f0',
                color: '#000',
            }}
        >
            0
        </Button>
        <Button 
            onClick={() => handleDecimalClick(SelectPaymentMethods)} 
            style={{
                padding: '20px',
                fontSize: '1.2em',
                backgroundColor: '#f0f0f0',
                color: '#000',
            }}
        >
            .
        </Button>
        <Button 
            onClick={() => clearAmount(SelectPaymentMethods)} 
            style={{
                padding: '20px',
                fontSize: '1.2em',
                backgroundColor: '#f8d7da',
                color: '#000',
            }}
        >
            CLR
        </Button>
       
        {/* Full Width Button as Last Row */}
      <Button
       onClick={() => exactAmount(SelectPaymentMethods)} 
        style={{
          padding: "20px",
          fontSize: "1.2em",
          backgroundColor: "lightblue",
          color: "#000",
          gridColumn: "1 / -1", // Make the button span all columns
        }}
        aria-label="Last Row Button"
      >
        Exact Amount
      </Button>
    </div>
);
const handleCardTypeChange = (e) => {
  const selectedType = e.target.value;
  setSelectedCardType(selectedType);
  const relevantItems = discount.filter(item => item.sequence === selectedType);
  setCardDiscount(relevantItems);
};

const getTotalPrice = (discountP) => {
  let subtotal = 0;
  let totalTaxAmount = 0;
  let totalDiscountPrice = 0;
  let discount = 0;
  let gross__Total = 0;

  if (discountP?.length > 0) {
    discount = discountP[0]?.discountPercent || 0;

    if (discount >= 0) {

      gross__Total = grossTotal;
      totalDiscountPrice = (gross__Total * discount) / 100;
      subtotal = gross__Total - totalDiscountPrice;

      if (Tax?.length) {
        totalTaxAmount = Tax.reduce((sum, taxItem) => {
          const taxPercentage = taxItem.taxPrese || 0;
          const price = subtotal || 0;
          
          if (taxItem.taxCode === "VAT") {
            setVat_Total(parseFloat((price * taxPercentage) / 100).toFixed(2));
          }

          return sum + (price * taxPercentage) / 100;
        }, 0);
      }
    }

    setTax_Total(parseFloat(totalTaxAmount.toFixed(2)));
    setDiscount_Amount(parseFloat(totalDiscountPrice.toFixed(2)));
    setGrossTotal(parseFloat(gross__Total.toFixed(2)));
    setSubTotal(parseFloat(subtotal.toFixed(2)));
    setTotalPrice(parseFloat((subtotal + totalTaxAmount).toFixed(2)));
  } else {
    totaltax();
    totalVat();
    setGrossTotal(parseFloat(gross__Total.toFixed(2)));
    setSubTotal(parseFloat(gross__Total.toFixed(2)));
    setTotalPrice(parseFloat(Total.toFixed(2)));
  }
};


useEffect(() => {
  getTotalPrice(cardDiscount)
}, [cardDiscount]);

const amountDisplay = (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginLeft: '50px',
      marginTop: '20px',
      width: '80%',
      flex: 1,
    }}
  >
    {/* Display Tax List */}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {Tax &&
        Tax.map((T, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1px',
              marginTop:"-40px"
            }}
          >
            <span
              style={{
                fontSize: '16px',
                color: '#555',
                fontWeight: '600',
              }}
            >
              {T.taxCode}: {T.taxPrese}%
            </span>
            <div
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
               {vat_Total? vat_Total : T.taxTotal}
            </div>
          </div>
        ))}
    </div>

    {/* Card Type and Discount */}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '1px',
        }}
      >
        <span
          style={{
            fontSize: '16px',
            color: '#555',
            fontWeight: '600',
            marginTop:"-20px"
          }}
        >
          <select
            value={selectedCardType}
            onChange={handleCardTypeChange}
            style={{
              marginLeft: 'auto',
              width: '200px',
              fontSize: '1.0em',
              fontWeight: 'bold',
              padding: '5px',
              height: '40px',
            }}
          >
            <option value="" disabled>
              Select Discount
            </option>
            {cardTypes.map((cardType) => (
              <option key={cardType} value={cardType}>
                {cardType.charAt(0).toUpperCase() + cardType.slice(1)}
              </option>
            ))}
          </select>
           {cardDiscount[0]?.discountPercent}    %
        </span>
        <div
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#333',
          }}
        >
           {discount_Amount}
        </div>
      </div>
    </div>
{/* <hr /> */}
    {/* Display Total Amount */}
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop:"-20px"
      }}
    >
      <span
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#555',
          fontWeight: '600',
        }}
      >
        Total Amount:
      </span>
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          color: 'green',
        }}
      >
         {parseFloat(Total_Price).toFixed(2)}
      </div>
    </div>

    {/* Display Balance */}
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop:"-20px"
      }}
    >
      <span
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#555',
          fontWeight: '600',
        }}
      >
        Balance:
      </span>
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          color: 'red', // Balance in red
        }}
      >
         {changeToReturn.toFixed(2)}
      </div>
    </div>
  </div>
);


const incrementButtons = (
  <div style={{
      display: 'flex',
      flexDirection: 'column',
      //gap: '5px',  // Reduced the gap between buttons
      marginLeft: '50px',
      marginTop:"-20px",
      width: '80%', // Make the increment buttons smaller
      flex: 1, // Ensures the section stretches to match the height
  }}>
      { [5000, 1000, 500, 100, 10].map(amount => (
          <Button 
              key={amount}

              onClick={() => handleIncrementClick(amount,SelectPaymentMethods)}
              style={{
                  padding: '20px', // Increased height of buttons
                  fontSize: '1.2em',
                  backgroundColor: '#d4edda',
                  color: '#000',
                  width: '100%',
                  marginBottom: '5px'
              }}
          >
              +{amount}
          </Button>
      )) }
  </div>
);

  const handleReceivedAmountChange = (value, type) => {
    const amountReceived = parseFloat(value);
    switch (type) {
      case "CASH":
        setReceivedAmount(amountReceived);
        break;
    
      case "COM":
        setReceivedAmountCOMBANK(amountReceived)
        break;
    
      case "SAM":
        setReceivedAmountSAMPATH(amountReceived)
        break;
    
      case "AMEX":
        setReceivedAmountAMEX(amountReceived)
        break;
    
      case "GIFT":
        setReceivedAmountGTV(amountReceived)
        break;
        
        case "DFCC":
          setReceivedAmountDFCC(amountReceived)
        break;
    
      default:
        console.error("Invalid payment type");
    };

    setChangeToReturn(amountReceived - Total_Price); 
    setRecivedTotalAmount(amountReceived)
  };

  // var paymethod = () => {
    
  //   if (Tax) {
  //     Tax.forEach( (VAT) => {
  //       if(VAT.taxType=== "VAT")
  //         vat_Total=VAT.taxTotal || 0;
  //     });
  //     if (discount.length === 0) {
  //       Total_Price = ((Total)-vat_Total).toFixed(2) || 0;
  //     }
  //     else{
  //       Total_Price = (Total_Price)-vat_Total || 0;
  //     }
  //   }
  //   return vat_Total;
  // };

  var totalVat = () => {
    
    if (Tax) {
      Tax.forEach( (VAT) => {
        if(VAT.taxCode=== "VAT"){
          setVat_Total(VAT.taxTotal || 0)
        }
      });
    };
  };

  var totaltax = () => {
    var TaxTotal = 0 
    if (Tax) {
      Tax.forEach((TOT) => {
          TaxTotal += parseFloat(TOT.taxTotal) || 0;
          
          if(taxes){
            taxes.forEach((TT) => {
            if (TOT.taxCode === TT.taxCode) {
              if (TT.taxListId) {
                  taxIds += taxIds ? `,${TT.taxListId}` : TT.taxListId;
              }
            }
          });
        }
      });
      setTax_Total(TaxTotal)
    }
  };

  const fetchDiscount = async () => {
    try {
      const response = await discountService().getAlldiscounts();
      if(response){
        
        setDiscount(response);

        const uniqueCardTypes = [...new Set(response.map(item => item.sequence))];
        setCardTypes(uniqueCardTypes);
        setCard(true)
      }

    } catch (error) {
      console.error('Error fetching suppliers data:', error);
    }
  };

  const fetchTaxes = async () => {
    try {
      const response = await taxServices().getAllTax();
      setTaxes(response.data); 
    } catch (error) {
      console.error('Error fetching suppliers data:', error);
    }
    };
  const colectiveData = async () => {
    var cashAmount =0;
    var cardAmount =0;
    var giftCardNo = '';
    var payment01= 0;
    var payment02= 0;
    var payment03= 0;
    var payment04= 0;
    var payment05= 0;
    var payment06= 0;
    var payment07= 0;
    var payment08= 0;
    var payment09= 0;
    var payment10= 0;


    var remarks = [
      receivedAmount,
      receivedAmountCOMBANK,
      receivedAmountSAMPATH,
      receivedAmountAMEX,
      receivedAmountGTV,
      receivedAmountDFCC,
    ].join('^');
    

  if (receivedAmount !== 0) {
      cashAmount = parseFloat(receivedAmount);
      if (receivedAmount) {
        discountSequences += discountSequences ? `,${"CASH"}` : "CASH";
        payment01 = parseFloat(receivedAmount)
      }

  }

  if (receivedAmountCOMBANK !== 0) {
      cardAmount += parseFloat(receivedAmountCOMBANK);
      if (receivedAmountCOMBANK) {
        discountSequences += discountSequences ? `,${"COM"}` : "COM";
        payment02 = parseFloat(receivedAmountCOMBANK)
      }
  }

  if (receivedAmountSAMPATH !== 0) {
      cardAmount += parseFloat(receivedAmountSAMPATH);
      if (receivedAmountSAMPATH) {
        discountSequences += discountSequences ? `,${"SAM"}` : "SAM";
        payment03 = parseFloat(receivedAmountSAMPATH)
      }
  }

  if (receivedAmountAMEX !== 0) {
      cardAmount += parseFloat(receivedAmountAMEX);
      if (receivedAmountAMEX) {
        discountSequences += discountSequences ? `,${"AMEX"}` : "AMEX";
        payment04 = parseFloat(receivedAmountAMEX)
      }
  }

  if (receivedAmountGTV !== 0) {
    if (receivedAmountGTV_NO == 0) {
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Please Enter Gift Card Number`,
        confirmButtonColor: '#d33',
        allowOutsideClick: false,
      });
      return;
    }
    cardAmount += parseFloat(receivedAmountGTV);
    giftCardNo = receivedAmountGTV_NO
    if (receivedAmountGTV) {
      discountSequences += discountSequences ? `,${"GIFT"}` : "GIFT";
      payment05 = parseFloat(receivedAmountGTV)
    }

  }
  if (receivedAmountDFCC !== 0) {
    cardAmount += parseFloat(receivedAmountDFCC);
    if (receivedAmountDFCC) {
      discountSequences += discountSequences ? `,${"DFCC"}` : "DFCC";
      payment06 = parseFloat(receivedAmountDFCC)
    }
}
  
    try {
      
      await printService().handleSaveCard("Active",cardList);
      // await printService().handleGateControlCard("Active",cardList);
      const response = await dishesServices().postColectiveData(
        gross_Total,
        sub_Total,
        Total_Price,
        cashAmount,
        cardAmount,
        paymentMethod,
        discountSequences,
        taxIds,
        discount_Amount,
        Tax_Total,
        vat_Total,
        stall,
        transactionIds,
        selectedExecutiveType,
        remarks,
        giftCardNo,
        payment01,
        payment02,
        payment03,
        payment04,
        payment05,
        payment06,
        payment07,
        payment08,
        payment09,
        payment10,
        note,
      );
      setNotification({ message: "Order was successful!", show: true, type: 'success' });
      
      if (response && response.data) {
        try {
            debugger
            await printService().sendFullBill(response);
        } catch (error) {
            console.error("Failed to print full bill:", error);
        }
    
    

        setCollectiveTransactionID(response.data.collectiveTransactionId.collectiveTransactionID);
        setShowCustomerModal(true);
    }
      
    } catch (error) {
      setNotification({ message: "Order failed. Please try again.", show: true, type: 'error' });
    }
  };

  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  }; 

  const handleNumberInputChange = (event) => {
    let { value } = event.target;
    value = value.replace(/[^0-9]/g, "").slice(0, 4);
    setNumberInput(value);
    setIsValid(value.length === 4);  
  };

  useEffect(() => {
    if (show) {
      setTotalPrice(parseFloat(Total))
      fetchDiscount();
      setReceivedAmount(0);
      fetchTaxes();
      setCard(!paymentMethod === 'CASH');
      setCardDiscount([]);
      setSelectedCardType('')
      setstall(false)
      setChangeToReturn(0);
      setNumberInput("");
      setNote("");
      setSelectedExecutiveType("");
      setReceivedAmount(0);
      setReceivedAmountCOMBANK(0);
      setReceivedAmountSAMPATH(0);
      setReceivedAmountAMEX(0);
      setReceivedAmountGTV(0);
      setReceivedAmountDFCC(0);
      totaltax();
      totalVat();
      setDiscount_Amount(0);
    }
  }, [show]);


  useEffect(() => {
    setChangeToReturn((parseFloat(receivedAmount)+
    parseFloat(receivedAmountCOMBANK)+
    parseFloat(receivedAmountSAMPATH)+
    parseFloat(receivedAmountAMEX)+
    parseFloat(receivedAmountGTV)+
    parseFloat(receivedAmountDFCC)) - Total_Price); // Update changeToReturn when receivedAmount changes
  }, [receivedAmount,receivedAmountCOMBANK,receivedAmountSAMPATH,receivedAmountAMEX,receivedAmountDFCC,receivedAmountGTV, Total_Price]);
  
  useEffect(() => {
    setRecivedTotalAmount(parseFloat(receivedAmount)+
    parseFloat(receivedAmountCOMBANK)+
    parseFloat(receivedAmountSAMPATH)+
    parseFloat(receivedAmountAMEX)+
    parseFloat(receivedAmountGTV)+
    parseFloat(receivedAmountDFCC)); // Update changeToReturn when receivedAmount changes
  }, [receivedAmount,receivedAmountCOMBANK,receivedAmountSAMPATH,receivedAmountAMEX,receivedAmountDFCC,receivedAmountGTV]);
  return (
    <>
    <ToastContainer />
    <Modal
        show={show}
        onHide={onHide}
        size="lg"
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >

        <Modal.Body style={{ padding: '20px', backgroundColor: '#f9f9f9' }} closeButton>
            <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '2', display: 'flex', justifyContent: 'space-between', fontSize: '1.5em' }}>
              {/* Left Section: Payment details and inputs */}
              <div style={{ flex: 1, marginRight: '20px' }}>
                
              <div>
              <style>
                {`
                  .custom-table {
                    width: 100%;
                    font-size: 1.7rem; /* Increase font size */
                    border-collapse: collapse; /* Ensure borders are consistent */
                    border: 5px solid #ddd; /* Add border around the table */
                  }

                  .custom-table th,
                  .custom-table td {
                    padding: 15px; /* Add padding for better readability */
                    border: 5px solid #ccc; /* Add border lines for cells */
                    vertical-align: middle;
                  }

                  .custom-table th {
                    background-color: #f4f4f4; /* Add a light background for headers */
                    color: #333; /* Darker text color */
                  }

                  .custom-table tr {
                    height: 70px; /* Make rows taller */
                  }

                  .custom-table tbody tr:nth-child(odd) {
                    background-color: #f9f9f9; /* Alternate row colors */
                  }

                  .custom-table tbody tr:nth-child(even) {
                    background-color: #fff;
                  }

                  .custom-table input {
                    font-size: 1.7rem; /* Adjust input font size */
                    height: 50px; /* Increase input height */
                    padding: 5px;
                  }
                `}
              </style>
              <div className="table-responsive">
                <table className="table custom-table text-center">
                  <thead>
                    <tr>
                      <th>Payment Type</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CASH</td>
                      <td>
                        <input
                          type="text"
                          readOnly
                          // defaultValue="10,077.00"
                          className="form-control text-center"
                          value={receivedAmount ? receivedAmount : ''}
                          onChange={(e) => handleReceivedAmountChange(e.target.value, 'CASH')}
                          onKeyPress={handleKeyPress}
                          onClick={() => setSelectPaymentMethods('CASH')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>COMMERCIAL</td>
                      <td>
                        <input
                          type="text"
                          readOnly
                          // defaultValue="10,077.00"
                          className="form-control text-center"
                          value={receivedAmountCOMBANK ? receivedAmountCOMBANK : ''}
                          onChange={(e) => handleReceivedAmountChange(e.target.value, 'COM')}
                          onClick={() => setSelectPaymentMethods('COM')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>SAMPATH</td>
                      <td>
                        <input
                          type="text"
                          readOnly
                          // defaultValue="10,077.00"
                          className="form-control text-center"
                          value={receivedAmountSAMPATH ? receivedAmountSAMPATH : ''}
                          onChange={(e) => handleReceivedAmountChange(e.target.value, 'SAM')}
                          onKeyPress={handleKeyPress}
                          onClick={() => setSelectPaymentMethods('SAM')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>AMEX</td>
                      <td>
                        <input
                          type="text"
                          readOnly
                          // defaultValue="10,077.00"
                          className="form-control text-center"
                          value={receivedAmountAMEX ? receivedAmountAMEX : ''}
                          onChange={(e) => handleReceivedAmountChange(e.target.value, 'AMEX')}
                          onKeyPress={handleKeyPress}
                          onClick={() => setSelectPaymentMethods('AMEX')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>GIFT VOUC</td>
                      <td>
                        <input
                          type="text"
                          readOnly
                          // defaultValue="10,077.00"
                          className="form-control text-center"
                          value={receivedAmountGTV ? receivedAmountGTV : ''}
                          onChange={(e) => handleReceivedAmountChange(e.target.value, 'GIFT')}
                          onKeyPress={handleKeyPress}
                          onClick={() => setSelectPaymentMethods('GIFT')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>DFCC</td>
                      <td>
                        <input
                          type="text"
                          readOnly
                          // defaultValue="10,077.00"
                          className="form-control text-center"
                          value={receivedAmountDFCC ? receivedAmountDFCC : ''}
                          onChange={(e) => handleReceivedAmountChange(e.target.value, 'DFCC')}
                          onKeyPress={handleKeyPress}
                          onClick={() => setSelectPaymentMethods('DFCC')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '0px' }}>
                <div style={{ marginRight: '10px' }}>Add Gift Card</div>
                <input
                  type="text"
                  readOnly
                  className="form-control text-center"
                  placeholder="Gift Card No"
                  style={{ width: '150px' }} // Adjust the width as needed
                  value={receivedAmountGTV_NO ? receivedAmountGTV_NO : ''}
                  onChange={(e) => handleReceivedAmountChange(e.target.value, 'GIFT_NO')}
                  onKeyPress={handleKeyPress}
                  onClick={() => setSelectPaymentMethods('GIFT_NO')}
                />
              </div>
              <hr/>
                <div style={{ marginTop: '0px' }}>
                <div style={{ marginRight: '10px' }}>Add Note</div>
                          <textarea
                            id="note"
                            name="note"
                            placeholder="Enter note here..."
                            rows="1"
                            value={note}
                            onChange={handleNoteChange}
                            style={{
                              width: '100%',
                              padding: '10px',
                              fontSize: '0.9em',
                              borderRadius: '5px',
                              border: '1px solid #ccc',
                              resize: 'none',
                            }}
                          ></textarea>
                      </div> 
              </div>

              {/* Right Section: posKeypad */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px' }}>
              <div style={{ marginBottom: '10px' }}>
                {amountDisplay}
              </div>

              <div style={{ display: 'flex', gap: '20px' }}>
              <div style={{ display: 'flex', gap: '20px' }}>
              <div style={{ flexBasis: '200px', marginTop: '-10px' }}>
                {incrementButtons}
              </div>
              <div style={{ flexBasis: '300px', marginTop: '0' }}>
                {posKeypad}
              </div>
            </div>

              </div>
            </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button 
                onClick={() => setstall(prev => !prev)} 
                style={{ backgroundColor: stall ? 'green' : 'red', color: 'white' }}
                hidden={true}
            >
                VAT FREE
            </Button>
            <Button
          onClick={() => {
            colectiveData();
            onHide();
            finish();
          }}
          style={{
            fontSize: "1.5rem", // Bigger text
            padding: "15px 30px", // Larger padding
            borderRadius: "8px", // Rounded corners
            // backgroundColor: "#007bff", // Button color
            color: "#fff", // Text color
            border: "none", // Remove border
            cursor: "pointer", // Pointer cursor for better UX
          }}
          disabled={recivedTotalAmount === 0}
        >
          PAY BY {paymentMethod}
        </Button>
        <Button
        onClick={() => {
          onHide();

        }}
        style={{
          fontSize: "1.5rem", // Bigger text
          padding: "15px 30px", // Larger padding
          borderRadius: "8px", // Rounded corners
          backgroundColor: "red", // Button color
          color: "#fff", // Text color
          border: "none", // Remove border
          cursor: "pointer", // Pointer cursor for better UX
        }}
        >EXIT</Button>
        </Modal.Footer >
    </Modal>

    {notification.type === "error" && notification.show && (
      <Modal show={notification.show} onHide={() => setNotification({ ...notification, show: false })} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaExclamationCircle size={24} style={{ color: 'red', marginRight: '12px' }} />
              <span>An Error Occurred</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {notification.message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setNotification({ ...notification, show: false })}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )}

    {notification.type === "success" && showCustomerModal && (
      <CustomerContactModal
        show={showCustomerModal}
        onClose={() => setShowCustomerModal(false)}
        onSuccess={() => console.log("Customer details saved!")}
        transactionID={collectiveTransactionID}
        successMessage="Payment Successful"
      />
    )}
    </>
  );
}

export default MultiPaymentModal;
