import React from "react";
import {API_CONFIGURATIONS} from "../constants/apiConfigurations";
import axios from "axios";
import qs from "qs";
import {generateDefaultLogoutTime} from "../../../src/utils/timeUtils";

const dishesServices = () => {
    
    const getAll = async () => {
        const response = await axios
            .get(API_CONFIGURATIONS.GET_ALL_DISHERS)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error.response;
            });
        return response;
    };
    
    const getAllOreders = async () => {
        const response = await axios
            .get(API_CONFIGURATIONS.GET_ORDERS)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error.response;
            });
        return response;
    };

    const getFilteredOrders = async (stallIds, statusChange) => {
      try {
        const response = await axios.get(API_CONFIGURATIONS.GET_FILTERED_ORDERS, {
          params: {
            stallIds,
            statusChange,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        });
    
        return response.data;
      } catch (error) {
        console.error("Error fetching filtered orders:", error.response || error.message);
        throw error.response || new Error("Failed to fetch filtered orders.");
      }
    };    
  
    const getAllinprogressOders = async (cardId) => {
      const response = await axios
          .get(`${API_CONFIGURATIONS.GET_IN_PROGRESS_ORDERS}/${cardId}`)
          .then((response) => {
              return response;
          })
          .catch((error) => {
              throw error.response;
          });
      return response;
  };

    const getAllCards = async () => {
      const response = await axios
          .get(API_CONFIGURATIONS.GET_CARD)
          .then((response) => {

              return response;
          })
          .catch((error) => {
              throw error.response;
          });
      return response;
  };
  const getAllManagerCards = async () => {
      const response = await axios
          .get(API_CONFIGURATIONS.GET_MANAGER_CARD)
          .then((response) => {

              return response;
          })
          .catch((error) => {
              throw error.response;
          });
      return response;
  };

    const getAllCashier = async () => {
      const response = await axios
          .get(API_CONFIGURATIONS.GET_CASHIER)
          .then((response) => {
              return response;
          })
          .catch((error) => {
              throw error.response;
          });
      return response;
  };

    const getTodayCashierTransactions = async () => {
      const response = await axios
          .get(API_CONFIGURATIONS.GET_CASHIER_TODAY)
          .then((response) => {
              return response;
          })
          .catch((error) => {
            console.error("Error fetching today's cashier transactions:", error);
              throw error.response;
          });
      return response;
  };

    const getAllCardOreders = async (cardId) => {
        const response = await axios
        .get(`${API_CONFIGURATIONS.GET_ALL_CARD_ORDERS}/${cardId}`)
            .then((response) => {
               
                return response.data;
            })
            .catch((error) => {
                throw error.response;
            });
        return response;
    };

    const getAllTableOreders = async (tableId) => {
        const response = await axios.get(`${API_CONFIGURATIONS.GET_ALL_TABLE_ORDERS}/${tableId}`)
            .then((response) => { 
                return response.data;
            })
            .catch((error) => {
                throw error.response;
            });
        return response;
    };

    const getAllStallOreders = async (stallID) => {
      const response = await axios
      .get(`${API_CONFIGURATIONS.GET_ORDERS}`)
          .then((response) => {
            const orders = response.data;
            const filteredOrders = orders.filter(order => order.stallId === stallID && order.statusChange==="INPROGRESS");
              return filteredOrders;
          })
          .catch((error) => {
              throw error.response;
          });
      return response;
  };

    const getAllTables = async () => {
      const response = await axios
          .get(API_CONFIGURATIONS.GET_TABLES)
          .then((response) => {
              return response;
          })
          .catch((error) => {
              throw error.response;
          });
      return response;
  };

      const postData = async (orderData,totalPrice,TaxesPrice,SubTotal,cardNo,kCCID,Status,TableIds,TableStatus,stallIdInt) => {
        try {
          const employeeID = localStorage.getItem('employeeID');
          const response = await axios
          .post(API_CONFIGURATIONS.POST_ORDERS,
            {
              transactionID: 0,
              visitId: 0,
              customerId: 0,
              cashierId: 0,
              employeeID:employeeID || '',
              rfidCardId: kCCID, // This is the phsicalnumber of the card
              cardId: cardNo || 0,
              totalAmount: totalPrice,
              taxesPrice: TaxesPrice,
              subTotal: SubTotal,
              TableIds: TableIds || "-",
              collectiveTransactionID:null,
              statusChange: "INPROGRESS",
              stallId: localStorage.getItem('stallID') || '',
              status: Status,
              createdByID: 0,
              createdByScreenID: "string",
              lastModifiedByID: 0,
              lastModifiedByScreenID: "string",
              transactionItems: orderData,
              tableStatus: TableStatus,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response;
          
        } catch (error) {
          console.error(error.message);
          throw new Error(error.message);
        }
      };

      const submitOutletOrder = async (
        orderData,
        totalPrice,
        TaxesPrice,
        SubTotal,
        Status,
        TableIds,
        TableStatus,
        stallId
      ) => {
        try {
          const employeeID = localStorage.getItem('employeeID');
          const response = await axios.post(
            API_CONFIGURATIONS.POST_ORDERS,
            {
              transactionID: 0,
              visitId: 0,
              customerId: 0,
              cashierId: 0,
              employeeID: employeeID || "",
              rfidCardId: 0,
              cardId: 0,
              totalAmount: totalPrice,
              taxesPrice: TaxesPrice,
              subTotal: SubTotal,
              TableIds: TableIds || "NO",
              collectiveTransactionID: null,
              statusChange: "INPROGRESS",
              stallId: stallId,
              status: Status,
              createdByID: 0,
              createdByScreenID: "string",
              lastModifiedByID: 0,
              lastModifiedByScreenID: "string",
              transactionItems: orderData,
              tableStatus: TableStatus,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response;
          
        } catch (error) {
            throw new Error(error.message);
        }
      };
      
      const postColectiveData = async (
        gross_Total,
        sub_Total,
        totalBill,
        cashAmount,
        cardAmount,
        paymentMethod,
        discountSequences,
        taxIds,
        discount_Amount,
        taxTotal,
        vat_Total,
        stall,
        transactionIds,
        executiveType,
        remarks,
        giftCardNo,
        payment01,
        payment02,
        payment03,
        payment04,
        payment05,
        payment06,
        payment07,
        payment08,
        payment09,
        payment10,
        note
      ) => {
        try {
          const employeeID = localStorage.getItem("employeeID");
           
          const response = await axios.post(
            API_CONFIGURATIONS.POST_COLLECTIVE_ORDERS,
            {
              grossTotal:gross_Total,
              subTotal:sub_Total,
              totalBill: totalBill,
              cashAmount: cashAmount,
              cardAmount: cardAmount,
              paymentMethod: paymentMethod,
              discountSequences: discountSequences,
              taxIds: taxIds,
              discount: discount_Amount,
              taxTotal: taxTotal,
              vatTotal: vat_Total,
              vat: stall,
              stallId: localStorage.getItem("stallID"),
              employeeID: employeeID || "",
              transactionIds: transactionIds,
              ExecutiveType: executiveType,
              remarks:remarks,
              giftCardNo:giftCardNo,
              payment01,
              payment02,
              payment03,
              payment04,
              payment05,
              payment06,
              payment07,
              payment08,
              payment09,
              payment10,
              note: note,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response;
        } catch (error) {
          throw new Error(error.message);
        }
      };

      const postOutletColectiveData = async (
        gross_Total,
        sub_Total,
        totalBill,
        cashAmount,
        cardAmount,
        paymentMethod,
        discountSequences,
        taxIds,
        discount_Amount,
        taxTotal,
        vat_Total,
        stall,
        transactionIds,
        executiveType,
        remarks,
        giftCardNo,
        payment01,
        payment02,
        payment03,
        payment04,
        payment05,
        payment06,
        payment07,
        payment08,
        payment09,
        payment10,
        note
      ) => {
        try {
          const employeeID = localStorage.getItem("employeeID");

          const response = await axios.post(
            API_CONFIGURATIONS.POST_OUTLET_COLLECTIVE_ORDERS,
            {
              grossTotal: gross_Total,
              subTotal: sub_Total,
              totalBill: totalBill,
              cashAmount: cashAmount,
              cardAmount: cardAmount,
              paymentMethod: paymentMethod,
              discountSequences: discountSequences,
              taxIds: taxIds,
              discount: discount_Amount,
              taxTotal: taxTotal,
              vatTotal: vat_Total,
              vat: stall,
              stallId: localStorage.getItem("stallID"),
              employeeID: employeeID || "",
              transactionIds: transactionIds,
              ExecutiveType: executiveType,
              remarks: remarks,
              giftCardNo: giftCardNo,
              payment01:payment01,
              payment02:payment02,
              payment03:payment03,
              payment04:payment04,
              payment05:payment05,
              payment06:payment06,
              payment07:payment07,
              payment08:payment08,
              payment09:payment09,
              payment10:payment10,
              note: note,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response;
        } catch (error) {
          if (error.response && error.response.data) { 
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        }
      };


      const updateOutletColectiveData = async (
        gross_Total,
        sub_Total,
        totalBill,
        cashAmount,
        cardAmount,
        paymentMethod,
        discountSequences,
        taxIds,
        discount_Amount,
        taxTotal,
        vat_Total,
        stall,
        transactionIds,
        executiveType,
        remarks,
        giftCardNo,
        payment01,
        payment02,
        payment03,
        payment04,
        payment05,
        payment06,
        payment07,
        payment08,
        payment09,
        payment10,
        note
      ) => {
        try {
          const employeeID = localStorage.getItem("employeeID");
           
          const response = await axios.post(
            API_CONFIGURATIONS.UPDATE_OUTLET_COLLECTIVE_ORDERS,
            {
              grossTotal: gross_Total,
              subTotal: sub_Total,
              totalBill: totalBill,
              cashAmount: cashAmount,
              cardAmount: cardAmount,
              paymentMethod: paymentMethod,
              discountSequences: discountSequences,
              taxIds: taxIds,
              discount: discount_Amount,
              taxTotal: taxTotal,
              vatTotal: vat_Total,
              vat: stall,
              stallId: localStorage.getItem("stallID"),
              employeeID: employeeID || "",
              transactionIds: transactionIds,
              ExecutiveType: executiveType,
              remarks: remarks,
              giftCardNo: giftCardNo,
              payment01:payment01,
              payment02:payment02,
              payment03:payment03,
              payment04:payment04,
              payment05:payment05,
              payment06:payment06,
              payment07:payment07,
              payment08:payment08,
              payment09:payment09,
              payment10:payment10,
              note: note,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response;
        } catch (error) {
          throw new Error(error.message);
        }
      };

        const getMenuItemsByStall = async (stallId) => {
            const response = await axios
                .get(`${API_CONFIGURATIONS.GET_MENU_ITEMS_BY_STALL}/${stallId}`)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    throw error.response;
                });
            return response;
        };

        const login = async (username, password) => {
          try {
              const response = await axios.post(API_CONFIGURATIONS.GET_LOGIN, 
                  {
                      UserName: username,
                      Password: password,
                  },
                  {
                      headers: { "Content-Type": "application/json" },
                  }
              );
      
              const { employeeID, stallID } = response.data;
              const utcTime = new Date().toISOString();
            
              // Check or create a log detail record
              const logDetailResponse = await axios.post(API_CONFIGURATIONS.POST_LOG_DETAIL, 
                  {
                      employeeID: employeeID,
                      stallId: stallID[0],
                      loginTime: utcTime,
                  },
                  {
                      headers: { "Content-Type": "application/json" },
                  }
              );      
              return response;
      
          } catch (error) {
              throw new Error(error.response?.data || error.message);
          }
      };

      const updateLogoutTime = async (logId, logoutTime) => {
        try {
            const response = await axios.put(
                `${API_CONFIGURATIONS.UPDATE_LOGOUT_TIME}/${logId}`,
                { LogoutTime: logoutTime.toISOString() },
                {
                    headers: { "Content-Type": "application/json" }
                }
            );
            console.log("Logout time updated successfully");
            return response.data;
        } catch (error) {
            console.error("Error updating logout time:", error.response?.data || error.message);
            throw error;
        }
    };
    
      
        const postVoidData = async (
          orderData,
          totalPrice,
          TaxesPrice,
          SubTotal,
          fromVoidId,
          cardNo,
          Status,
          TableIds,
          TableStatus,
          stallIdInt,
          cardData
        ) => {
          try {
             
            const response = await axios.post(
              API_CONFIGURATIONS.POST_VOID_ORDERS,
              {
                transactionID: 0,
                visitId: 0,
                customerId: 0,
                cashierId: 0,
                employeeID: localStorage.getItem("employeeID") || "",
                rfidCardId: 0,
                cardId: cardData.cardId,
                totalAmount: totalPrice,
                taxesPrice: TaxesPrice,
                subTotal: SubTotal,
                TableIds: TableIds || "-",
                collectiveTransactionID: null,
                statusChange: "INPROGRESS",
                stallId: localStorage.getItem("stallID") || "",
                status: false,
                createdByID: 0,
                createdByScreenID: "string",
                lastModifiedByID: 0,
                lastModifiedByScreenID: "string",
                transactionItems: orderData,
                TableStatus: TableStatus,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
                params: { fromVoidId },
              }
            );
            
            return response;
          } catch (error) {
            throw new Error(error.message);
          }
        };

        const postOutletVoidData = async (
          orderData,
          totalPrice,
          TaxesPrice,
          SubTotal,
          fromVoidId,
          Status,
          TableIds,
          TableStatus,
          stallId
        ) => {
          try {
            const response = await axios.post(
              API_CONFIGURATIONS.POST_VOID_ORDERS,
              {
                transactionID: 0,
                visitId: 0,
                customerId: 0,
                cashierId: 0,
                employeeID: localStorage.getItem("employeeID") || "",
                rfidCardId: 0,
                cardId: 0,
                totalAmount: totalPrice,
                taxesPrice: TaxesPrice,
                subTotal: SubTotal,
                TableIds: TableIds || "NO",
                collectiveTransactionID: null,
                statusChange: "INPROGRESS",
                stallId: stallId,
                status: Status,
                createdByID: 0,
                createdByScreenID: "string",
                lastModifiedByID: 0,
                lastModifiedByScreenID: "string",
                transactionItems: orderData,
                TableStatus: TableStatus,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
                params: { fromVoidId },
              }
            );

            return response;
          } catch (error) {
            throw new Error(error.message);
          }
        };

        const updateTransactionStatusToVoid = async (
          transactionId,
          voidReason,
          managerUsername
        ) => {
          try {
            
            const requestBody = {
              voidReason: voidReason,
              managerId: managerUsername,
            };

            const response = await axios.put(
              `${API_CONFIGURATIONS.PUT_ORDERS}/${transactionId}`,
              requestBody
            );
 
            return response.data;
          } catch (error) {
            throw error.response;
          }
        };

        const updateOutletTransactionsStatusToVoid = async (
          transactionIds, 
          voidReason,
          managerUsername
        ) => {
          try {
            const requestBody = {
              transactionIds,  
              voidReason,
              managerId: managerUsername,
            };
 
            const response = await axios.put(`${API_CONFIGURATIONS.PUT_OUTLET_VOID_ORDERS}`, requestBody);

            return response.data;
          } catch (error) {
            console.error("Error updating transactions:", error);
            throw error.response;
          }
        };

        const getVoidReasons = async () => {
          const response = await axios
            .get(API_CONFIGURATIONS.GET_VOID_REASONS)
            .then((response) => {
               
              return response.data;
            })
            .catch((error) => {
              throw error.response;
            });
          return response;
        };

        const validManager = async (username, password) => {
          try {
            const response = await axios.post(
              API_CONFIGURATIONS.POST_VALIDATE_MANAGER,
              {
                Username: username,  
                Password: password,
              },
              {
                headers: { "Content-Type": "application/json" },
              }
            );

            if (response.status === 200) {
              return {
                success: true,
                message: response.data,  
              };
            } else {
              return {
                success: false,
                message: "Unexpected response from server.",
              };
            }
          } catch (error) {
            return {
              success: false,
              message:
                error.response?.data ||
                "Login failed. Please check your credentials.",
            };
          }
        };

        const validAccountant = async (username, password) => {
          try {
            const response = await axios.post(
              API_CONFIGURATIONS.POST_VALIDATE_ACCOUNTANT,
              {
                Username: username,  
                Password: password,
              },
              {
                headers: { "Content-Type": "application/json" },
              }
            );

            if (response.status === 200) {
              return {
                success: true,
                message: response.data,  
              };
            } else {
              return {
                success: false,
                message: "Unexpected response from server.",
              };
            }
          } catch (error) {
            return {
              success: false,
              message:
                error.response?.data ||
                "Login failed. Please check your credentials.",
            };
          }
        };

        const validateUser = async (username, password) => {
          const roleEndpoints = {
            manager: API_CONFIGURATIONS.POST_VALIDATE_MANAGER,
            accountant: API_CONFIGURATIONS.POST_VALIDATE_ACCOUNTANT,
            chiefCashier: API_CONFIGURATIONS.POST_VALIDATE_CHIEF_CASHIER,
          };
        
          for (const [role, endpoint] of Object.entries(roleEndpoints)) {
            try {
              const response = await axios.post(
                endpoint,
                { Username: username, Password: password },
                { headers: { "Content-Type": "application/json" } }
              );
        
              if (response.status === 200) {
                return {
                  success: true,
                  role,
                  message: response.data,
                };
              }
            } catch (error) {
              if (error.response?.status !== 401) {
                console.error(`Error validating ${role}:`, error.message);
              }
            }
          }
        
          return {
            success: false,
            message: "Invalid credentials for all roles.",
          };
        };        

        const getLogDetails = async () => {
          const response = await axios.get(API_CONFIGURATIONS.GET_LOG_DETAIL)
              .then((response) => {
                  return response;
              })
              .catch((error) => {
                  throw error.response;
              });
          return response;
      };

      const putLogDetails = async (logId, updatedData) => {
        try {
            const response = await axios.put(`${API_CONFIGURATIONS.PUT_LOG_DETAIL}/${logId}`, updatedData);
            return response;
        } catch (error) {
            throw error.response;
        }
    };
 
    const handleFloatValue = async (floatValue, cashierId, forceReplace = false) => {
      try {
        const response = await axios.put(
          `${API_CONFIGURATIONS.HANDLE_FLOAT_VALUE}/${cashierId}?forceReplace=${forceReplace}`,
          { floatValue }
        );
        return response;
      } catch (error) {
        console.error("Error in request:", error);
        throw error.response;
      }
    };

    const handleExit = async (logId, employeeID) => {
        try {
            const response = await axios.put(
                `${API_CONFIGURATIONS.HANDLE_EXIT_STATUS}/${logId}`,
                { employeeID },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return response;
        } catch (error) {
            console.error("Error while handling exit:", error);
            throw error.response;
        }
    };

    const handleUnsettle = async (cardId) => {
      try {
        const response = await axios.put(
          `${API_CONFIGURATIONS.UPDATE_ORDER_STATUS}/${cardId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error while handling unsettled order:", error);
        throw error.response || new Error("Failed to update order status.");
      }
    };
    
    const postCustomerContact = async (collectiveTransactionID, customerName, phoneNumber) => {
      try {
        const response = await axios.post(
          API_CONFIGURATIONS.POST_CUSTOMER_CONTACTS,
          {
            collectiveTransactionID,
            customerName: customerName || null,
            phoneNumber: phoneNumber || null,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return response;
      } catch (error) {
        console.error("Error posting customer contact:", error);
        throw error.response || error.message;
      }
    };

 
    return {
      getAllOreders,
      getAllManagerCards,
      getFilteredOrders,
      getAllinprogressOders,
      getAllCardOreders,
      postData,
      postColectiveData,
      getAll,
      getMenuItemsByStall,
      login,
      postVoidData,
      updateTransactionStatusToVoid,
      getAllTables,
      getAllCards,
      getAllCashier,
      getVoidReasons,
      validManager,
      validAccountant,
      validateUser,
      getAllStallOreders,
      getLogDetails,
      putLogDetails,
      handleFloatValue,
      handleExit,
      handleUnsettle,
      getAllTableOreders,
      updateLogoutTime,
      submitOutletOrder,
      postOutletColectiveData,
      updateOutletColectiveData,
      postOutletVoidData,
      updateOutletTransactionsStatusToVoid,
      getTodayCashierTransactions,
      postCustomerContact,
    };
}
export default dishesServices;