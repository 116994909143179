import React from 'react';

const TableSelectionModal = ({ isOpen, onClose, tables, selectedTableId, onTableSelect }) => {
  if (!isOpen) return null;

  // Group tables into rows of 5
  const rows = tables.reduce((acc, table, index) => {
    const rowIndex = Math.floor(index / 5);
    if (!acc[rowIndex]) acc[rowIndex] = [];
    acc[rowIndex].push(table);
    return acc;
  }, []);

  return (
    <div 
      className="modal fade show" 
      style={{ 
        display: isOpen ? 'block' : 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Select Table</h5>
            <button 
              type="button" 
              className="btn-close" 
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            {rows.map((row, rowIndex) => (
              <div 
                key={rowIndex} 
                className="d-flex flex-wrap mb-2"
                style={{ gap: '8px' }}
              >
                {row.map((table) => (
                  <div
                    key={table.tableId}
                    onClick={() => {
                      if (!table.alreadyBilled) {
                        onTableSelect(table.tableId);
                        onClose();
                      }
                    }}
                    style={{
                      backgroundColor: selectedTableId === table.tableId 
                        ? '#4CAF50'
                        : table.alreadyBilled 
                          ? '#FF7043' 
                          : table.alreadyAssigned 
                            ? '#FFB74D' 
                            : '#FFFFFF',
                      padding: '15px 16px',
                      borderRadius: '4px',
                      cursor: table.alreadyBilled ? 'not-allowed' : 'pointer',
                      color: '#000000',
                      fontWeight: '500',
                      fontSize: '13px',
                      minWidth: '60px',
                      textAlign: 'center',
                      flex: '1',
                      maxWidth: 'calc(100% / 5 - 7px)', // Changed from 6 to 5
                      border: '1px solid #ccc',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                    }}
                  >
                    {table.tableId}
                  </div>
                ))}
                {/* Add empty divs to maintain spacing when row is not complete */}
                {[...Array(5 - row.length)].map((_, i) => (
                  <div 
                    key={`empty-${i}`}
                    style={{
                      flex: '1',
                      maxWidth: 'calc(100% / 5 - 7px)', // Changed from 6 to 5
                      visibility: 'hidden'
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <button 
              type="button" 
              className="btn btn-secondary" 
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableSelectionModal;