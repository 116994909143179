import React, { useContext, useState } from 'react';
import { AppSettings } from './../../config/app-settings.js';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './posHeader.css';
import RolePermissions from '../../enums/rolePermissions.js';
import dishesServices from '../../api/DishesServices/disheseService.js';
import OpenCashierExitModal from '../../../src/pages/pos/cutomer-display/OpenCashierExitModal.jsx';

function PosHeader() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();
  const stallID = localStorage.getItem('stallID');
  const userRole = localStorage.getItem('roleName');
  const token = localStorage.getItem('token');
  const currentEmployeeID = localStorage.getItem('employeeID');
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const displayName = localStorage.getItem('displayName');
  if (!displayName) {
    console.warn("Display name not found in localStorage.");
  }

  const handleRefreshPage = () => {
    window.location.reload();
  };

  const handleLogout = async () => {
    setIsLoggingOut(true);
  
    try {
      const logDetailsResponse = await dishesServices().getLogDetails();
      const logDetails = logDetailsResponse.data;
      const employeeLogDetails = logDetails.filter(
        (log) => log.employeeID === currentEmployeeID
      );
  
      if (employeeLogDetails.length === 0) {
        Swal.fire("Error", "No log records found for the current employee.", "error");
        return;
      }
  
      const lastLogRecord = employeeLogDetails.sort(
        (a, b) => new Date(b.loginTime) - new Date(a.loginTime)
      )[0];
  
      if (!lastLogRecord.loginOutTime) {
        const utcNow = new Date();
        const sriLankaTime = new Date(utcNow.toLocaleString("en-US", { timeZone: "Asia/Colombo" }));

        await dishesServices().updateLogoutTime(lastLogRecord.logId, sriLankaTime);
        lastLogRecord.loginOutTime = sriLankaTime;
      }
      
      if (userRole === "Cashier" || userRole === "Outlet_Cashier") {
        const cashierDataResponse = await dishesServices().getTodayCashierTransactions();
        const cashierData = cashierDataResponse.data || [];
  
        const loginDate = new Date(lastLogRecord.loginTime);
        const logoutDate = new Date(lastLogRecord.loginOutTime);
  
        const employeeTransactions = cashierData.filter((transaction) => {
          const transactionDate = new Date(transaction.tStamp);
          return (
            transaction.employeeID === currentEmployeeID &&
            transactionDate >= loginDate &&
            transactionDate <= logoutDate
          );
        });
  
        if (employeeTransactions.length === 0) {
          Swal.fire("Info", "No transactions found for the current session.", "info");
          await proceedWithLogout();
          return;
        }
  
        const stalls = {};
        const processedData = [];
  
        employeeTransactions.forEach((item) => { 
          const { stallId, cardAmount, totalBill, paymentMethod, payment01, payment02, payment03, payment04, payment05, payment06 } = item;
        
          if (!stalls[stallId]) {
            stalls[stallId] = { CARD: 0, CASH: 0, COMMERCIAL: 0, SAMPATH: 0, AMEX: 0, GIFT_VOUCHER: 0, DFCC: 0 };
          }
        
          if (paymentMethod === "MULTY") {
            let cashValue = (totalBill - cardAmount);
            stalls[stallId].CASH += cashValue > 0 ? cashValue : 0;
            stalls[stallId].COMMERCIAL += payment02 || 0;
            stalls[stallId].SAMPATH += payment03 || 0;
            stalls[stallId].AMEX += payment04 || 0;
            stalls[stallId].GIFT_VOUCHER += payment05 || 0;
            stalls[stallId].DFCC += payment06 || 0;
            stalls[stallId].CARD += cardAmount || 0;
          } else {
            stalls[stallId].CARD += cardAmount || 0;
    
            let cashValue = payment01 || 0;
            stalls[stallId].CASH += cashValue > 0 ? cashValue : 0;
          }
      
          if (stalls[stallId].CASH < 0) {
              stalls[stallId].CASH = 0;
          }
        });
        
  
        Object.keys(stalls).forEach((stallId) => {
          processedData.push({
            employeeID: currentEmployeeID,
            displayName: displayName,
            stallId: stallId,
            floatValue: lastLogRecord.floatValue || 0,
            CARD: stalls[stallId].CARD,
            CASH: stalls[stallId].CASH,
            COMMERCIAL: stalls[stallId].COMMERCIAL,
            SAMPATH: stalls[stallId].SAMPATH,
            AMEX: stalls[stallId].AMEX,
            GIFT_VOUCHER: stalls[stallId].GIFT_VOUCHER,
            DFCC: stalls[stallId].DFCC,
            loginTime: lastLogRecord.loginTime,
            logoutTime: lastLogRecord.loginOutTime,
            logId: lastLogRecord.logId,
          });
        });        

        console.log("Processed Data:", processedData);
        if (processedData.length > 0) {
          setSummaryData(processedData);
          setModalShow(true);
        } else {
          console.warn("No processed data for modal.");
        }
        return
      }
  
      await proceedWithLogout();
    } catch (error) {
      console.error("Error during logout:", error);
      Swal.fire("Error", "An error occurred during logout. Please try again.", "error");
    } finally {
      setIsLoggingOut(false);
    }
  };

  const proceedWithLogout = async () => {
    localStorage.clear();
    Swal.fire({
      icon: "info",
      title: "Logged Out",
      text: "You have been logged out.",
      showConfirmButton: false,
      timer: 2000,
    }).then(() => {
      navigate("/login");
    });
  };

  const hasPermission = (roles) => roles.includes(userRole);

  return (
    <div className="pos-header">
      <div className="header-left">
      <Link  className="brand-logo">
        <img src="/assets/img/kcc-logo.png" className="header-logo" alt="KCC Logo" />
        </Link>
      </div>
      <div className="header-center">
        {hasPermission(RolePermissions.CustomerOrder) && (
            <Link to="/pos/customer-order" className="btn btn-primary" onClick={() => localStorage.removeItem("voidbill")}>Customer Order</Link>
        )}

        {hasPermission(RolePermissions.OutletCustomerOrder) && (
            <Link to="/pos/customer-order-outlet" className="btn btn-primary" onClick={() => {
              localStorage.removeItem("TAKEAWAY") 
              localStorage.removeItem("voidbill")
            }}>Customer Order</Link>
        )}

        {hasPermission(RolePermissions.KitchenOrder) && (
            <Link to="/pos/kitchen-order" className="btn btn-primary">Kitchen Order</Link>
        )}

        {hasPermission(RolePermissions.VoidOrder) && (
            <Link to="/pos/void-orders" className="btn btn-primary">Void Order</Link>
        )}

        {hasPermission(RolePermissions.CashierView) && (
            <Link to="/pos/cashier-checkout" className="btn btn-primary">Cashier View</Link>
        )}

        {hasPermission(RolePermissions.CounterCheckout) && (
            <Link to="/pos/counter-checkout" className="btn btn-primary">Counter Checkout</Link>
        )} 

        {hasPermission(RolePermissions.OutletCounterCheckout) && (
            <Link to="/pos/counter-checkout-outlet" className="btn btn-primary">Counter Checkout</Link>
        )}    

        {hasPermission(RolePermissions.TableManagement) && (
            <Link to="/pos/table-management" className="btn btn-primary">Table Management</Link>
        )}

        {hasPermission(RolePermissions.DayEnd) && (
            <Link to="/pos/day-end" className="btn btn-primary">Day End</Link>
        )}

        <div style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}></div>
        {hasPermission(RolePermissions.ReFresh) && (
            <button 
              onClick={handleRefreshPage} 
              className="btn btn-primary" 
              style={{ 
                backgroundColor: '#1E2A5E',
                borderRadius: '8px',
                border: 'none',
                padding: '15px 5px'
              }}
            >
                <i className="fa fa-refresh"></i>{' '}
                <span style={{ color: '#FFFFFF' }}>Refresh</span>
            </button>
        )}
        

      </div>
      <div className="header-right">
        <div className="menu-item dropdown">
          <a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
            <div className="user-icon">
              <i className="fa fa-user"></i>
            </div>
            {/* Temporarily Hidden the StallID */}
            {/* <div className="menu-text">{stallID}</div> */}
          </a>
          <div className="dropdown-menu dropdown-menu-end me-lg-3">
            <Link to="/profile" className="dropdown-item d-flex align-items-center">Edit Profile <i className="fa fa-user-circle fa-fw ms-auto text-body text-opacity-50"></i></Link>
            <Link to="/email/inbox" className="dropdown-item d-flex align-items-center">Inbox <i className="fa fa-envelope fa-fw ms-auto text-body text-opacity-50"></i></Link>
            <Link to="/calendar" className="dropdown-item d-flex align-items-center">Calendar <i className="fa fa-calendar-alt fa-fw ms-auto text-body text-opacity-50"></i></Link>
            <Link to="/settings" className="dropdown-item d-flex align-items-center">Setting <i className="fa fa-wrench fa-fw ms-auto text-body text-opacity-50"></i></Link>
            <div className="dropdown-divider"></div>
            <a
                href="/login"
                onClick={(e) => {
                    e.preventDefault();
                    if (!isLoggingOut) {
                        handleLogout();
                    }
                }}
                className="dropdown-item d-flex align-items-center"
            >
                {isLoggingOut ? 'Logging Out...' : 'Log Out'} 
                <i className="fa fa-toggle-off fa-fw ms-auto text-body text-opacity-50"></i>
            </a>
          </div>
        </div>
      </div>
      <OpenCashierExitModal
        isOpen={modalShow}
        onClose={() => setModalShow(false)}
        summaryData={summaryData}
      />
    </div>
  );
}

export default PosHeader;
