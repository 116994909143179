import React, { useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import dishesServices from "../../../api/DishesServices/disheseService";
import Swal from "sweetalert2";
import printService from '../../../api/PrintServices/printService.js';
import { useNavigate } from 'react-router-dom';

function CashierModal(props) {
  const [cashierAmount, setCashierAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedCashier, setselectedCashier] = useState("");
  const [errorMessage_2, seterrorMessage_2] = useState("");
  const cashierList = ["CASHIER1", "CASHIER2", "CASHIER3", "MONGOLIAN","CAFEWALK","GELETERIA","BAKESNCAKE","FOODBOOTH","TERRACEBAR","THEFRESH","SRILANKAN"];
  const navigate = useNavigate();
  

  const handleAmountChange = (e) => { 
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setCashierAmount(value);
    }
  };
  const handleCashierChange = (event) => {
    setselectedCashier(event.target.value);
  };
 
  const handleSubmit = async () => {
   if (cashierAmount) {
     const cashierId = localStorage.getItem("employeeID");

     if(selectedCashier){
        localStorage.removeItem('stallID'); 
        localStorage.setItem('stallID', selectedCashier);
        
        if(selectedCashier !=="CASHIER1" && selectedCashier !=="CASHIER2" && selectedCashier !=="CASHIER3")
        {
          const role = localStorage.setItem('roleName','Outlet_Cashier');
          navigate(`/pos/customer-order-outlet`);
        }
        
        
      }
      else{
        seterrorMessage_2('Select A Cashier');
        return;
      }
     try {
      
       const response = await dishesServices().handleFloatValue(parseFloat(cashierAmount),cashierId);
       
       if (response.data.hasExistingValue) { 
         const { lastLoginTime, existingFloatValue } = response.data;
         Swal.fire({
           title: "Existing Float Value",
           html: `A float value already exists for the previous session:<br><strong>Login Time:</strong> ${new Date(lastLoginTime).toLocaleString()}<br><strong>Float Value:</strong> ${existingFloatValue}`,
           icon: "warning",
           showCancelButton: true,
           confirmButtonText: "Yes, Replace",
           cancelButtonText: "No, Cancel",
         }).then(async (result) => {
           if (result.isConfirmed) {
             try {
               const replaceResponse = await dishesServices().handleFloatValue( parseFloat(cashierAmount), cashierId,  true );
               
               if (replaceResponse.data.success) {
                try {
                  await printService().sendFloatValue({
                    stallId: selectedCashier,
                    lastLoginTime: lastLoginTime,
                    cashierId: cashierId,
                    cashierAmount: cashierAmount,        
                  });
                  
                } catch (printError) {
                  console.error("Faild to print float receipt:", printError)
                }
                 Swal.fire(
                   "Updated!",
                   "The float value has been replaced.",
                   "success"
                 );
                 setCashierAmount("");
                 setErrorMessage("");
                 props.onSubmit(parseFloat(cashierAmount));
                 localStorage.removeItem("floatValue");
               }
             } catch (error) {
               setErrorMessage(
                 "Failed to replace float value. Please try again."
               );
             } finally {
               props.onHide(); 
             }
           } else {
             Swal.fire(
               "Cancelled",
               "Float value update was canceled.",
               "info"
             ).then(() => {
               props.onHide(); 
               localStorage.removeItem("floatValue");
             });
           }
         });
       } else if (response.data.success) { 
        try {
          await printService().sendFloatValue({
            stallId: selectedCashier,
            lastLoginTime: new Date().toISOString(),
            cashierId: cashierId,
            cashierAmount: cashierAmount, 
          });
        } catch (printError) {
          console.error("Failed to print float receipt:", printError);
        }
         setCashierAmount("");
         setErrorMessage("");
         props.onSubmit(parseFloat(cashierAmount));
         localStorage.removeItem("floatValue");
         Swal.fire(
           "Success!",
           "Float value updated successfully.",
           "success"
         ).then(() => {
           props.onHide();  
         });
       } else {
         setErrorMessage(
           response.data.message || "Failed to update float value."
         );
       }
     } catch (error) {
       setErrorMessage("An error occurred. Please try again.");
     }
   } else {
     alert("Please enter a valid amount.");
   }
 };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogClassName="responsive-modal"
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ width: "100%", textAlign: "center" }}
        >
          <div
            className="brand"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/img/kcc-logo.png"
              className="invert-dark"
              alt="KCC Logo"
              height="50"
              style={{ marginRight: "10px" }}
            />
            <h1 style={{ margin: 0, fontSize: "1.5rem" }}>KCC</h1>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <h4 style={{ fontSize: "1.2rem" }}>ENTER CASHIER FLOAT AMOUNT</h4>
        <Form.Group controlId="cashierAmount" style={{ marginTop: '20px' }}>
          <Form.Control
            type="text"
            placeholder="Enter amount"
            value={cashierAmount}
            onChange={handleAmountChange}
            style={{
              textAlign: 'center',
              fontSize: '1.1rem',
              border: '3px solid #ccc',
              borderRadius: '4px',
              padding: '10px',
              width: '100%',
              maxWidth: '300px',
              margin: '0 auto'
            }}
          />
        </Form.Group>
        {errorMessage && (
          <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>
        )}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center", flexDirection: 'column' }}>
      <Form.Group
        controlId="selectCashier"
        style={{
          marginBottom: '15px',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
      <Form.Select
        value={selectedCashier}
        onChange={handleCashierChange}
        style={{
          width: '100%',
          maxWidth: '300px',
          margin: '0 auto',
          textAlign: 'center',
          border: '3px solid #ccc',
          borderRadius: '4px',
          padding: '12px',
          fontSize: '1.1rem',
        }}
      >
        <option value="" disabled>
          Select the Terminal
        </option>
        {cashierList.map((name, index) => (
          <option
            key={index}
            value={name}
            style={{
              fontSize: '1.2rem',
              padding: '12px 10px',
            }}
          >
            {name}
          </option>
        ))}
      </Form.Select>
      </Form.Group>

        {errorMessage_2 && (
          <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage_2}</div>
        )}
        <Button onClick={handleSubmit} style={{ width: "30%", marginTop: '15px' }}>
          Submit
        </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default CashierModal;
